import { useMemo, useState } from "react";
import { ExecuteActionInput } from "src/graphql";
import { SelectOption } from "src/types";
import { IndividualActionData } from "../flow-graph/util";
import { Modal } from "../modal";
import { ScheduleActivityActionModalInput } from "./ScheduleActivityActionModalInput";
import { CreateReferralActionModalInput } from "./CreateReferralActionModalInput";
import { CreateCarePathwayDataModalInput } from "./CreateCarePathwayDataModalInput";
import { useAuthContext } from "src/hooks";

interface ActionDataCollectionProps {
  modalIsOpen: boolean;
  actionsToCollectDataFor: IndividualActionData[];
  assignableUserOptions: SelectOption<string>[];
  assignableUsersLoading: boolean;
  onRequestClose: () => void;
  onSubmit: (actionInputs: ExecuteActionInput[]) => void;
  onboardingToken?: string;
  selectedTemplateFamilyId?: string;
  organizationId?: string;
}

export const ActionDataCollectionModal: React.FC<ActionDataCollectionProps> = ({
  modalIsOpen,
  actionsToCollectDataFor,
  assignableUserOptions,
  assignableUsersLoading,
  onRequestClose,
  onSubmit,
  onboardingToken,
  selectedTemplateFamilyId,
  organizationId,
}) => {
  const { selectedOrganizationId } = useAuthContext();
  const [actionIndex, setActionIndex] = useState(0);
  const [actionData, setActionData] = useState<ExecuteActionInput[]>([]);

  const handleIterateAndSaveData = (nextData: ExecuteActionInput) => {
    const nextActionData = [...actionData, nextData];
    const nextActionIndex = actionIndex + 1;

    // submit if final, otherwise iterate
    if (nextActionIndex === actionsToCollectDataFor.length) {
      setActionData([]);
      setActionIndex(0);
      onSubmit(nextActionData);
    } else {
      setActionData(nextActionData);
      setActionIndex(nextActionIndex);
    }
  };

  const currentAction = useMemo(
    () => actionsToCollectDataFor[actionIndex],
    [actionsToCollectDataFor, actionIndex]
  );

  if (!currentAction) return null;
  else
    return (
      <Modal onClose={onRequestClose} opened={modalIsOpen}>
        <ActionInput
          action={currentAction}
          onCompleteAction={handleIterateAndSaveData}
          onboardingToken={onboardingToken}
          assignableUserOptions={assignableUserOptions}
          assignableUsersLoading={assignableUsersLoading}
          selectedTemplateFamilyId={selectedTemplateFamilyId}
          organizationId={organizationId ?? selectedOrganizationId}
        />
      </Modal>
    );
};

type ActionInputProps = {
  organizationId: string;
  action: IndividualActionData;
  assignableUserOptions: SelectOption<string>[];
  assignableUsersLoading: boolean;
  onCompleteAction: (nextInput: ExecuteActionInput) => void;
  onboardingToken?: string;
  selectedTemplateFamilyId?: string;
};

const ActionInput = ({
  action,
  onCompleteAction,
  onboardingToken,
  assignableUserOptions,
  assignableUsersLoading,
  selectedTemplateFamilyId,
  organizationId,
}: ActionInputProps) => {
  switch (action.__typename) {
    case "CreateGoalData":
      return <></>;
    case "ScheduleActivityData":
      return (
        <ScheduleActivityActionModalInput
          completeFunction={(data) => {
            const { scheduleImmediately, ...rest } = data;
            onCompleteAction(rest);
          }}
          templateId={action.activityTemplateId}
          onboardingToken={onboardingToken}
          assignableUserOptions={assignableUserOptions}
          assignableUsersLoading={assignableUsersLoading}
          relatedCarePathwayTemplateFamilyId={selectedTemplateFamilyId}
        />
      );
    case "UpdateGoalData":
      return <></>;
    case "CreateReferralData":
      return (
        <CreateReferralActionModalInput
          completeFunction={onCompleteAction}
          templateId={action.referralTemplateId}
          onboardingToken={onboardingToken}
          assignableUserOptions={assignableUserOptions}
          assignableUsersLoading={assignableUsersLoading}
        />
      );
    case "StartCarePathwayData":
      return (
        <CreateCarePathwayDataModalInput
          completeFunction={onCompleteAction}
          carePathwayTemplateFamilyId={action.carePathwayTemplateFamilyId}
          onboardingToken={onboardingToken}
          assignableUserOptions={assignableUserOptions}
          assignableUsersLoading={assignableUsersLoading}
          organizationId={organizationId}
        />
      );
    case "UpdateCarePathwayStatusData":
      return <></>;
    case "CreateConsentData":
      return <></>;
    default:
      return <></>;
  }
};
