import { ScheduleActivityActionModalInput } from "./ScheduleActivityActionModalInput";
import {
  ExecuteActionInput,
  ScheduleActivityInput,
  ScheduleTypeEnum,
  useQueryCarePathwayTemplateById,
} from "src/graphql";
import { SelectOption } from "src/types";

import { useEffect, useMemo, useState } from "react";

interface CreateCarePathwayInputProps {
  carePathwayTemplateFamilyId?: string;
  assignableUserOptions: SelectOption<string>[];
  assignableUsersLoading: boolean;
  completeFunction: (actionInfo: ExecuteActionInput) => void;
  onboardingToken?: string;
  organizationId: string;
}

export const CreateCarePathwayDataModalInput: React.FC<
  CreateCarePathwayInputProps
> = ({
  completeFunction,
  carePathwayTemplateFamilyId,
  assignableUserOptions,
  assignableUsersLoading,
  onboardingToken,
  organizationId,
}) => {
  const carePathwayTemplateQuery = useQueryCarePathwayTemplateById({
    carePathwayTemplateFamilyId: carePathwayTemplateFamilyId ?? "",
    carePathwayId: undefined,
    skip: carePathwayTemplateFamilyId === undefined,
    onboardingToken,
    organizationId,
  });

  const [actionData, setActionData] = useState<ScheduleActivityInput[]>([]);

  const activityToSchedule = useMemo(() => {
    const carePathwayTemplate =
      carePathwayTemplateQuery.data?.carePathwayTemplateById?.data;

    const firstStep = carePathwayTemplate?.steps[0];
    return firstStep?.activityTemplateId ?? undefined;
  }, [carePathwayTemplateQuery.data?.carePathwayTemplateById?.data]);

  const completeSingleActivityScheduling = (
    scheduledActivityData: ScheduleActivityInput
  ) => {
    if (activityToSchedule)
      return completeFunction({
        carePathwayTemplateFamilyId: carePathwayTemplateFamilyId ?? "",
        activities: [...actionData, scheduledActivityData],
      });

    setActionData((prev) => [...prev, scheduledActivityData]);
  };

  useEffect(() => {
    if (activityToSchedule && carePathwayTemplateQuery.loading === false)
      completeFunction({
        carePathwayTemplateFamilyId: carePathwayTemplateFamilyId ?? "",
        activities: [],
      });
  }, [
    activityToSchedule,
    carePathwayTemplateQuery.loading,
    carePathwayTemplateFamilyId,
    completeFunction,
  ]);

  if (carePathwayTemplateQuery.loading) return null;

  if (activityToSchedule)
    return (
      <ScheduleActivityActionModalInput
        completeFunction={completeSingleActivityScheduling}
        templateId={activityToSchedule}
        onboardingToken={onboardingToken}
        assignableUserOptions={assignableUserOptions}
        assignableUsersLoading={assignableUsersLoading}
        relatedCarePathwayTemplateFamilyId={
          carePathwayTemplateQuery.data?.carePathwayTemplateById?.data?.familyId
        }
      />
    );

  return <></>;
};
