import { NumberInput } from "@mantine/core";
import { useField } from "formik";
import {
  extractWrapperProps,
  FormikInputBaseProps,
  FormikInputWrapper,
} from "src/components/input/FormikInputWrapper";
import { formatMoney } from "src/utils/format-money";

type FormikPriceInputProps = FormikInputBaseProps<number>;

export const FormikPriceInput = (props: FormikPriceInputProps) => {
  const [field, , helpers] = useField(props);

  const onChange = (value: "" | number) => {
    helpers.setValue(typeof value === "string" ? 0 : value);
  };

  return (
    <FormikInputWrapper {...extractWrapperProps(props)}>
      {/* https://mantine.dev/core/number-input/#parser-and-formatter */}
      <NumberInput
        {...field}
        disabled={props.disabled}
        precision={2}
        onChange={onChange}
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        formatter={(value) => formatMoney(value)}
      />
    </FormikInputWrapper>
  );
};
