import { GET_ACTIVITIES } from "../Activity/queries";
import { GET_ACTIVITY_TEMPLATES } from "../ActivityTemplate";
import { GET_BUILDER_GRAPH } from "../Builder/queries";
import { CARE_PATHWAYS_BY_MEMBER_ID } from "../CarePathway/queries";
import {
  AutoGeneratedNodesAndEdges,
  CarePathwayResponse,
  CarePathwayTemplateFamiliesResponse,
  CarePathwayTemplateResponse,
  CarePathwayTemplatesResponse,
  CarePathwayTemplateStep,
  MutationCopyCarePathwayTemplateArgs,
  MutationCreateOrUpdateCarePathwayTemplateArgs,
  MutationDeleteCarePathwayTemplateArgs,
  MutationGenerateStepsFromTemplateArgs,
  MutationMakeCarePathwayTemplateDefaultArgs,
  MutationRemoveCarePathwayTemplateFromDefaultArgs,
  MutationRetireCarePathwayTemplateArgs,
  MutationStartCarePathwayTemplateForMemberArgs,
  MutationUpdateCarePathwayStatusArgs,
  MutationUpdateCarePathwayTemplateDefaultAssignedUserGroupArgs,
  QueryCarePathwayTemplateByIdArgs,
  QueryCarePathwayTemplateDataByMemberIdArgs,
  QueryCarePathwayTemplateFamiliesByOrganizationIdArgs,
  QueryCarePathwayTemplatesByMemberIdArgs,
} from "../schemaTypes";
import {
  COPY_CARE_PATHWAY_TEMPLATE,
  CREATE_OR_UPDATE_CAREPATHWAY_TEMPLATE,
  DELETE_CARE_PATHWAY_TEMPLATE,
  GENERATE_STEPS_FROM_TEMPLATE,
  MAKE_CARE_PATHWAY_TEMPLATE_DEFUALT,
  REMOVE_CARE_PATHWAY_TEMPLATE_FROM_DEFUALT,
  RETIRE_CARE_PATHWAY_TEMPLATE,
  START_CARE_PATHWAY_TEMPLATE_FOR_MEMBER,
  UPDATE_CARE_PATHWAY_STATUS,
  UPDATE_CAREPATHWAY_TEMPLATE_DEFAULT_ASSIGNED_USER_GROUP,
} from "./mutations";
import {
  GET_CAREPATHWAY_TEMPLATE_BY_ID,
  GET_CAREPATHWAY_TEMPLATE_DATA_BY_MEMBER_ID,
  GET_CAREPATHWAY_TEMPLATES,
  GET_CAREPATHWAY_TEMPLATES_BY_MEMBER_ID,
} from "./queries";

import { useMutation, useQuery } from "@apollo/client";

export const useQueryCarePathwayTemplateFamilies = (
  organizationId: string,
  includePriorVersions: boolean
) =>
  useQuery<
    {
      carePathwayTemplateFamiliesByOrganizationId: CarePathwayTemplateFamiliesResponse;
    },
    QueryCarePathwayTemplateFamiliesByOrganizationIdArgs
  >(GET_CAREPATHWAY_TEMPLATES, {
    variables: {
      organizationId,
      includePriorVersions,
    },
  });

export const useQueryMemberCarePathways = (
  organizationId: string,
  memberId: string
) =>
  useQuery<
    { carePathwayTemplatesByMemberId: CarePathwayTemplatesResponse },
    QueryCarePathwayTemplatesByMemberIdArgs
  >(GET_CAREPATHWAY_TEMPLATES_BY_MEMBER_ID, {
    variables: {
      organizationId,
      memberId,
    },
    initialFetchPolicy: "network-only",
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

type QueryCarePathwayTemplateByIdOptions = {
  organizationId: string;
  carePathwayTemplateId?: string;
  carePathwayTemplateFamilyId?: string;
  carePathwayId: string | undefined;
  skip?: boolean;
  onboardingToken?: string;
  onCompleted?: (data: {
    carePathwayTemplateById: CarePathwayTemplateResponse;
  }) => void;
};

export const useQueryCarePathwayTemplateById = ({
  carePathwayTemplateId,
  carePathwayTemplateFamilyId,
  carePathwayId,
  skip = false,
  onboardingToken,
  organizationId,
  onCompleted,
}: QueryCarePathwayTemplateByIdOptions) =>
  useQuery<
    { carePathwayTemplateById: CarePathwayTemplateResponse },
    QueryCarePathwayTemplateByIdArgs
  >(GET_CAREPATHWAY_TEMPLATE_BY_ID, {
    variables: {
      organizationId,
      carePathwayTemplateId,
      carePathwayTemplateFamilyId,
      carePathwayId,
    },
    nextFetchPolicy: "no-cache",
    skip,
    context: onboardingToken
      ? { headers: { "Pear-Onboarding-Survey": onboardingToken } }
      : {},
    onCompleted,
  });

export const useQueryMemberCarePathwayTemplateData = (
  organizationId: string,
  carePathwayId: string,
  memberId: string,
  skip?: boolean
) =>
  useQuery<
    { carePathwayTemplateDataByMemberId: CarePathwayResponse },
    QueryCarePathwayTemplateDataByMemberIdArgs
  >(GET_CAREPATHWAY_TEMPLATE_DATA_BY_MEMBER_ID, {
    variables: {
      organizationId,
      carePathwayId,
      memberId,
    },
    skip: skip,
    initialFetchPolicy: "network-only",
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

export const useMutationCreateOrUpdateCarePathwayTemplate = (
  organizationId: string
) =>
  useMutation<
    { createOrUpdateCarePathwayTemplate: CarePathwayTemplateResponse },
    MutationCreateOrUpdateCarePathwayTemplateArgs
  >(CREATE_OR_UPDATE_CAREPATHWAY_TEMPLATE, {
    refetchQueries: [
      GET_BUILDER_GRAPH,
      GET_ACTIVITY_TEMPLATES,
      {
        query: GET_CAREPATHWAY_TEMPLATES,
        variables: { organizationId, includePriorVersions: true },
      },
    ],
  });

export const useMutationUpdateCarePathwayTemplateDefaultAssignedUserGroup = (
  organizationId: string
) =>
  useMutation<
    {
      updateCarePathwayTemplateDefaultAssignedUserGroup: CarePathwayTemplateResponse;
    },
    MutationUpdateCarePathwayTemplateDefaultAssignedUserGroupArgs
  >(UPDATE_CAREPATHWAY_TEMPLATE_DEFAULT_ASSIGNED_USER_GROUP, {
    refetchQueries: [
      {
        query: GET_CAREPATHWAY_TEMPLATES,
        variables: { organizationId, includePriorVersions: false },
      },
    ],
  });

export const useMutationMakeCarePathwayTemplateDefault = (
  organizationId: string
) =>
  useMutation<
    { makeCarePathwayTemplateDefault: CarePathwayTemplateResponse },
    MutationMakeCarePathwayTemplateDefaultArgs
  >(MAKE_CARE_PATHWAY_TEMPLATE_DEFUALT, {
    refetchQueries: [
      {
        query: GET_CAREPATHWAY_TEMPLATES,
        variables: { organizationId, includePriorVersions: false },
      },
    ],
  });

export const useMutationRemoveCarePathwayTemplateFromDefault = (
  organizationId: string
) =>
  useMutation<
    { removeCarePathwayTemplateFromDefault: CarePathwayTemplateResponse },
    MutationRemoveCarePathwayTemplateFromDefaultArgs
  >(REMOVE_CARE_PATHWAY_TEMPLATE_FROM_DEFUALT, {
    refetchQueries: [
      {
        query: GET_CAREPATHWAY_TEMPLATES,
        variables: { organizationId, includePriorVersions: false },
      },
    ],
  });

export const useMutationStartCarePathwayTemplateForMember = (
  organizationId: string
) =>
  useMutation<
    { startCarePathwayTemplateForMember: CarePathwayResponse },
    MutationStartCarePathwayTemplateForMemberArgs
  >(START_CARE_PATHWAY_TEMPLATE_FOR_MEMBER, {
    refetchQueries: (result) => [
      {
        query: GET_CAREPATHWAY_TEMPLATES_BY_MEMBER_ID,
        variables: {
          organizationId,
          memberId:
            result.data?.startCarePathwayTemplateForMember.data?.memberId,
        },
      },
      {
        query: CARE_PATHWAYS_BY_MEMBER_ID,
        variables: {
          organizationId,
          memberId:
            result.data?.startCarePathwayTemplateForMember.data?.memberId,
        },
      },
      GET_ACTIVITIES,
    ],
  });

export const useMutationDeleteCarePathwayTemplate = (organizationId: string) =>
  useMutation<
    { deleteCarePathwayTemplate: CarePathwayTemplateResponse },
    MutationDeleteCarePathwayTemplateArgs
  >(DELETE_CARE_PATHWAY_TEMPLATE, {
    refetchQueries: [
      {
        query: GET_CAREPATHWAY_TEMPLATES,
        variables: { organizationId, includePriorVersions: true },
      },
    ],
  });

export const useMutationCopyCarePathwayTemplate = (organizationId: string) =>
  useMutation<
    { copyCarePathwayTemplate: CarePathwayTemplateResponse },
    MutationCopyCarePathwayTemplateArgs
  >(COPY_CARE_PATHWAY_TEMPLATE, {
    refetchQueries: [
      {
        query: GET_CAREPATHWAY_TEMPLATES,
        variables: { organizationId, includePriorVersions: false },
      },
    ],
  });

export const useMutationRetireCarePathwayTemplate = (organizationId: string) =>
  useMutation<
    { retireCarePathwayTemplate: CarePathwayTemplateResponse },
    MutationRetireCarePathwayTemplateArgs
  >(RETIRE_CARE_PATHWAY_TEMPLATE, {
    refetchQueries: [
      {
        query: GET_CAREPATHWAY_TEMPLATES,
        variables: { organizationId, includePriorVersions: true },
      },
    ],
  });

export const useMutationUpdateCarePathwayStatus = (organizationId: string) =>
  useMutation<
    { updateCarePathwayStatus: CarePathwayResponse },
    MutationUpdateCarePathwayStatusArgs
  >(UPDATE_CARE_PATHWAY_STATUS, {
    refetchQueries: (result) => [
      {
        query: CARE_PATHWAYS_BY_MEMBER_ID,
        variables: {
          organizationId,
          memberId: result.data?.updateCarePathwayStatus.data?.memberId,
        },
      },
    ],
  });

export const useMutationGenerateStepsFromTemplate = () =>
  useMutation<
    { generateStepsFromTemplate: AutoGeneratedNodesAndEdges },
    MutationGenerateStepsFromTemplateArgs
  >(GENERATE_STEPS_FROM_TEMPLATE);
