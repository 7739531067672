import type { MemberFormValues } from "../MemberFormFormik";
import {
  MemberFieldState,
  MemberProfileSettings,
  PhoneNumberType,
} from "src/graphql";

import { Grid, TextInput, Title } from "@mantine/core";
import { Field, useFormikContext } from "formik";
import { v4 } from "uuid";

interface BasicInformationProps {
  memberProfileSettings: MemberProfileSettings;
}

export const BasicMemberInfoFormSection = ({
  memberProfileSettings,
}: BasicInformationProps) => {
  const { errors, touched, setValues } = useFormikContext<MemberFormValues>();

  return (
    <>
      <Title order={2} style={{ marginBottom: 20 }}>
        Basic Info
      </Title>
      <Grid gutter="md">
        <Grid.Col md={4} sm={6}>
          <Field
            as={TextInput}
            label="First Name"
            name="firstName"
            type="text"
            placeholder="Enter First Name"
            required
            variant="filled"
            radius="md"
            error={touched.firstName && errors.firstName}
          />
        </Grid.Col>
        <Grid.Col md={4} sm={6}>
          <Field
            as={TextInput}
            label="Last Name"
            name="lastName"
            type="text"
            placeholder="Enter Last Name"
            required
            variant="filled"
            radius="md"
            error={touched.lastName && errors.lastName}
          />
        </Grid.Col>
        {memberProfileSettings.memberPhoneticState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={4} sm={6}>
            <Field
              as={TextInput}
              label="Name Pronunciation"
              name="phonetic"
              type="text"
              placeholder="Enter Name Pronunciation"
              variant="filled"
              radius="md"
              error={touched.phonetic && errors.phonetic}
              required={
                memberProfileSettings.memberPhoneticState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        <Grid.Col md={4} sm={6}>
          <Field
            as={TextInput}
            label="Phone"
            name="contactInfo.phoneNumbers.0.digits"
            type="tel"
            placeholder="Enter Phone"
            required
            variant="filled"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValues((prev) => ({
                ...prev,
                contactInfo: {
                  ...prev.contactInfo,
                  phoneNumbers: [
                    {
                      _id: v4(),
                      digits: e.target.value,
                      doNotCall: false,
                      isPrimary: true,
                      type: { label: "Mobile", value: PhoneNumberType.Mobile },
                    },
                  ],
                },
              }));
            }}
            radius="md"
          />
        </Grid.Col>
      </Grid>
      <Grid gutter="md" style={{ marginTop: 20 }}>
        <Grid.Col md={6}>
          <Field
            as={TextInput}
            label="Email"
            name="contactInfo.email"
            type="email"
            placeholder="Enter Email"
            variant="filled"
            radius="md"
            error={touched?.contactInfo?.email && errors.contactInfo?.email}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};
