import {
  carePathwayFields,
  carePathwayTemplateFields,
  carePathwayTemplateMinimalFields,
  StepsFields,
} from "./types";

import { gql } from "@apollo/client";

export const CREATE_OR_UPDATE_CAREPATHWAY_TEMPLATE = gql`
  mutation CreateOrUpdateCarePathwayTemplate($input: CarePathwayTemplateInput!) {
    createOrUpdateCarePathwayTemplate(input: $input) {
      success
      message
      data {
        ${carePathwayTemplateFields}
      }
    }
  }
`;

export const UPDATE_CAREPATHWAY_TEMPLATE_DEFAULT_ASSIGNED_USER_GROUP = gql`
  mutation UpdateCarePathwayTemplateDefaultAssignedUserGroup($input: CarePathwayTemplateUpdateDefaultAssignedUserGroupInput!) {
    updateCarePathwayTemplateDefaultAssignedUserGroup(input: $input) {
      success
      message
      data {
        ${carePathwayTemplateFields}
      }
    }
  }
`;

export const MAKE_CARE_PATHWAY_TEMPLATE_DEFUALT = gql`
  mutation MakeCarePathwayTemplateDefault(
    $carePathwayTemplateId: ID!
    $organizationId: ID!
    ) {
    makeCarePathwayTemplateDefault(
      carePathwayTemplateId: $carePathwayTemplateId
      organizationId: $organizationId
      ) {
      success
      message
      data {
        ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const REMOVE_CARE_PATHWAY_TEMPLATE_FROM_DEFUALT = gql`
  mutation RemoveCarePathwayTemplateFromDefault(
    $carePathwayTemplateId: ID!
    $organizationId: ID!) {
    removeCarePathwayTemplateFromDefault(
      carePathwayTemplateId: $carePathwayTemplateId
      organizationId: $organizationId
      ) {
      success
      message
      data {
        ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const START_CARE_PATHWAY_TEMPLATE_FOR_MEMBER = gql`
  mutation StartCarePathwayTemplateForMember(
    $carePathwayTemplateId: ID!
    $memberId: ID!
    $userId: ID!
    $organizationId: ID!
    $executeActionInput: [ExecuteActionInput]) {
    startCarePathwayTemplateForMember(
    carePathwayTemplateId: $carePathwayTemplateId
    memberId: $memberId
    userId: $userId
    organizationId: $organizationId
    executeActionInput: $executeActionInput) {
      success
      message
      data {
        ${carePathwayFields}
      }
    }
  }
`;

export const DELETE_CARE_PATHWAY_TEMPLATE = gql`
  mutation DeleteCarePathwayTemplate($carePathwayTemplateId: ID!, $organizationId: ID!) {
    deleteCarePathwayTemplate(carePathwayTemplateId: $carePathwayTemplateId, organizationId: $organizationId) {
      success
      message
      data {
        ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const COPY_CARE_PATHWAY_TEMPLATE = gql`
  mutation CopyCarePathwayTemplate($input: CopyCarePathwayTemplateInput!) {
    copyCarePathwayTemplate(input: $input) {
      success
      message
      data {
        ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const RETIRE_CARE_PATHWAY_TEMPLATE = gql`
  mutation CarePathwayTemplate($input: CarePathwayTemplateIdAndOrgInput!) {
    retireCarePathwayTemplate(input: $input) {
      success
      message
      data {
        ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const UPDATE_CARE_PATHWAY_STATUS = gql`
  mutation UpdateCarePathwayStatus($input: UpdateCarePathwayStatusInput!) {
    updateCarePathwayStatus(input: $input) {
      success
        message
        data {
          ${carePathwayFields}
        }
    }
  }
`;

export const GENERATE_STEPS_FROM_TEMPLATE = gql`
  mutation GenerateStepsFromTemplate($steps: [CarePathwayTemplateStepInput!]!,$carePathwayTemplateId: ID!,$organizationId: ID!,$carePathwayId: ID) {
    generateStepsFromTemplate(steps: $steps,carePathwayTemplateId: $carePathwayTemplateId, organizationId: $organizationId,carePathwayId:$carePathwayId) {
      edges {
        id
        source
        target
        sourceHandle
        targetHandle
        style {
          strokeWidth
          stroke
          strokeDasharray
        }
      }
      nodes {
        ${StepsFields}
      }
    }
  }
`;
