import React, { useState, useEffect } from "react";
import toast from "src/libs/toast";
import log from "loglevel";

import {
  TextInput,
  Stack,
  Text,
  ScrollArea,
  Paper,
  Group,
  Box,
  Spoiler,
  ActionIcon,
  Tooltip,
} from "@mantine/core";

import { useRequest } from "src/hooks";
import {
  IconSend,
  IconRefresh,
  IconX,
  IconMessageQuestion,
} from "@tabler/icons-react";
import { ChevronDown, ChevronUp } from "react-feather";

import ReactMarkdown from "react-markdown";
import { v4 as uuidv4 } from "uuid";

interface ChatWindowProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ChatMessage {
  role: "user" | "ai";
  content: string;
}
export const ChatWindow = ({ isOpen, onClose }: ChatWindowProps) => {
  const request = useRequest();
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const chatEndRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      let storedSessionId = localStorage.getItem("chat_session_id");

      if (!storedSessionId) {
        storedSessionId = uuidv4();
        localStorage.setItem("chat_session_id", storedSessionId as string);
      }
      setSessionId(storedSessionId);

      const storedMessages = localStorage.getItem("chat_history");
      if (storedMessages) {
        try {
          setMessages(JSON.parse(storedMessages));
        } catch (error) {
          toast.error("Error parsing chat history.");
          log.error("Error parsing chat history:", error);
        }
      }
    }
  }, [isOpen]);

  /** Save chat history in localStorage and auto-scroll */
  useEffect(() => {
    localStorage.setItem("chat_history", JSON.stringify(messages));
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  /** Handle Sending a Message */
  const handleSendMessage = async () => {
    if (!question.trim()) return;

    setIsLoading(true);

    const userMessage: ChatMessage = { role: "user", content: question };
    setMessages((prev: ChatMessage[]) => [...prev, userMessage]);
    setQuestion("");

    try {
      const res = await request(
        "/ai-service/internal/ask/",
        {
          method: "POST",
          body: JSON.stringify({ question, session_id: sessionId }),
        },
        false,
        true,
        true
      );

      const data = await res.json();

      if (data.answer) {
        const aiMessage: ChatMessage = {
          role: "ai",
          content: data.answer,
        };

        setMessages((prev: ChatMessage[]) => [...prev, aiMessage]);
      }
    } catch (error) {
      setMessages((prev: ChatMessage[]) => [
        ...prev,
        { role: "ai", content: "Error fetching response." },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  /** Start a New Chat */
  const handleNewChat = () => {
    const newSessionId = uuidv4();
    localStorage.setItem("chat_session_id", newSessionId);
    localStorage.removeItem("chat_history");
    setSessionId(newSessionId);
    setMessages([]);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        right: isOpen ? 0 : "-420px",
        top: 0,
        height: "100vh",
        width: 400,
        backgroundColor: "#f8f9fa",
        borderLeft: "1px solid #ddd",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
        transition: "right 0.3s ease-in-out",
        zIndex: 100,
      }}
    >
      <Group
        position="apart"
        px="md"
        py="sm"
        sx={{ backgroundColor: "#fff", borderBottom: "1px solid #ddd" }}
      >
        <Group spacing="xs">
          <IconMessageQuestion size={22} color="var(--color-pear-green)" />
          <Text fw="bold" size="lg">
            Help Desk
          </Text>
        </Group>
        <Group>
          <Tooltip label="New Chat" withArrow>
            <ActionIcon
              onClick={handleNewChat}
              size="md"
              color="var(--color-pear-green)"
            >
              <IconRefresh size={18} />
            </ActionIcon>
          </Tooltip>
          <ActionIcon onClick={onClose} variant="light" size="md">
            <IconX size={18} />
          </ActionIcon>
        </Group>
      </Group>

      {/* Chat Messages */}
      <ScrollArea h="80%" px="md" pb="md" pt="md">
        <Stack spacing="xs">
          {messages.map((msg, index) => (
            <Paper
              key={index}
              shadow="xs"
              p="sm"
              radius="lg"
              withBorder
              style={{
                maxWidth: "75%",
                alignSelf: msg.role === "user" ? "flex-end" : "flex-start",
                backgroundColor:
                  msg.role === "user" ? "#007aff" : "var(--color-pear-green)",
                color: "white",
                borderRadius: "12px",
                padding: "10px 14px",
                fontSize: "14px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                overflowX: "auto",
                minWidth: 0,
                wordBreak: "break-word",
              }}
            >
              <Spoiler
                maxHeight={200}
                showLabel={
                  <Group position="right" spacing=".2em">
                    <Text c="white" size="sm">
                      Read more
                    </Text>
                    <ChevronDown size="1.2em" color="white" />
                  </Group>
                }
                hideLabel={
                  <Group position="right" spacing=".2em">
                    <Text c="white" size="sm">
                      Read less
                    </Text>
                    <ChevronUp size="1.2em" color="white" />
                  </Group>
                }
              >
                {msg.role === "ai" ? (
                  <ReactMarkdown>{msg.content}</ReactMarkdown>
                ) : (
                  msg.content
                )}
              </Spoiler>
            </Paper>
          ))}
          <div ref={chatEndRef} />
        </Stack>
      </ScrollArea>
      <Box px="md" py="sm">
        <Group position="apart" sx={{ width: "100%" }}>
          <TextInput
            placeholder="Ask a question..."
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            disabled={isLoading}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            radius="xl"
            size="md"
            sx={{ flexGrow: 1 }}
          />
          <ActionIcon
            onClick={handleSendMessage}
            loading={isLoading}
            size="lg"
            color="blue"
            radius="xl"
            sx={{ marginLeft: "5px" }}
          >
            <IconSend size={20} />
          </ActionIcon>
        </Group>
      </Box>
    </Box>
  );
};
