import { PreviewContainer, PreviewHeader } from "./shared";
import { ActivityTemplate } from "src/graphql";
import { sanitizeHtml } from "src/utils/htmlParser";

// TODO: should these previews have a bit more meat, perhaps about the activity it
//       schedules?

type ActivityTemplatePreviewProps = {
  activityTemplate: ActivityTemplate;
};

export const ActivityTemplatePreview = ({
  activityTemplate,
}: ActivityTemplatePreviewProps) => {
  return (
    <PreviewContainer>
      <PreviewHeader>Preview</PreviewHeader>
      {!activityTemplate && "<missing activity info>"}

      {activityTemplate && (
        <div style={{ padding: 3 }}>
          {/* Title */}
          <div style={{ marginTop: "0.25em" }}>{activityTemplate.title}</div>

          {/* Description */}
          <div
            style={{ marginTop: "0.5em" }}
            dangerouslySetInnerHTML={{
              __html:
                sanitizeHtml(activityTemplate.description) ??
                "<missing activity description>",
            }}
          />
        </div>
      )}
    </PreviewContainer>
  );
};
