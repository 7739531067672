import parsePhoneNumber from "libphonenumber-js";
export const formatPhoneNumber = (phoneNumberString: string | undefined) => {
  if (!phoneNumberString) return "";
  const phoneNumber = parsePhoneNumber(phoneNumberString);

  if (!phoneNumber?.isPossible()) return "";

  if (phoneNumber?.countryCallingCode === "1") {
    return phoneNumber.formatNational();
  }

  return phoneNumber.formatInternational();
};

export const formatPhoneAsE164 = (phoneNumberString: string) => {
  const phoneNumber = parsePhoneNumber(phoneNumberString, "US");

  if (!phoneNumber?.isValid()) return "";

  return phoneNumber.format("E.164");
};

export const formatNationalPhone = (phoneNumberString: string) => {
  const phoneNumber = parsePhoneNumber(phoneNumberString);
  if (!phoneNumber?.isValid()) return "";
  return phoneNumber.formatNational();
};

export const numberToE164OrString = (phoneNumberString: string) => {
  return formatPhoneAsE164(phoneNumberString) || phoneNumberString;
};
