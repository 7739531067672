import { GET_SUB_ORGS_BY_PARENT_ID } from "../HubControl/queries";
import { GET_MEMBERS } from "../Members/queries";
import {
  DefaultResponse,
  Domain,
  DomainResponse,
  GetBillingProviderResponse,
  GetBillingProvidersResponse,
  GetDomainsResponse,
  GetInsuranceAccountTransactionsInput,
  GetInsuranceAccountTransactionsResponse,
  GetInsuranceBillingConfigurationsResponse,
  GetOnboardingLinkResponse,
  GetOnboardingSurveysResponse,
  GetOrganizationResponse,
  GetUrlResponse,
  InsuranceAccountTransaction,
  MutationAddDefaultAnsweringUserArgs,
  MutationCreateDomainArgs,
  MutationCreateOrganizationArgs,
  MutationCreateOrUpdateBillingProviderArgs,
  MutationCreateOrUpdateCostConfigurationsArgs,
  MutationCreateOrUpdateInNetworkInsuranceBillingConfigurationArgs,
  MutationCreateOrUpdateOrganizationInsuranceBillingConfigurationArgs,
  MutationDeleteBillingProviderArgs,
  MutationDeleteCostConfigurationArgs,
  MutationDeleteInsuranceBillingConfigurationArgs,
  MutationDeleteOrganizationArgs,
  MutationDisableHubBillingArgs,
  MutationEnableHubBillingArgs,
  MutationRenameOrganizationInsuranceBillingConfigurationArgs,
  MutationSwapOrganizationInsuranceBillingConfigurationArgs,
  MutationToggleCarePlansArgs,
  MutationToggleEpisodesOfCareArgs,
  MutationToggleManageSeatsForSubOrgsArgs,
  MutationUpdateInternalToolsConfigArgs,
  MutationUpdateOrganizationArgs,
  MutationUpdateOrganizationBillingConfigurationArgs,
  MutationUpdateSeatsForSubOrgsArgs,
  MutationUpsertDomainArgs,
  MutationUpsertDomainDescriptionArgs,
  QueryDomainsByOrganizationIdArgs,
  QueryInNetworkInsuranceAccountTransactionsArgs,
  QueryOnboardingSurveysByOrganizationIdArgs,
  QueryOrganizationArgs,
  QueryOrganizationByOnboardingTokenArgs,
  UpdateOrganizationResponse,
} from "../schemaTypes";
import { GET_USER_AND_ORGS } from "../Users/queries";
import {
  ADD_DEFAULT_ANSWERING_USER,
  CREATE_DOMAIN,
  CREATE_OR_UPDATE_BILLING_PROVIDER,
  CREATE_OR_UPDATE_COST_CONFIGURATIONS,
  CREATE_OR_UPDATE_IN_NETWORK_INSURANCE_CONFIGURATION,
  CREATE_OR_UPDATE_ORGANIZATION_INSURANCE_BILLING_CONFIGURATION,
  CREATE_ORGANIZATION,
  DELETE_BILLING_PROVIDER,
  DELETE_COST_CONFIGURATION,
  DELETE_DOMAIN,
  DELETE_INSURANCE_BILLING_CONFIGURATION,
  DELETE_ONBOARDING_SURVEY,
  DELETE_ORGANIZATION,
  DISABLE_HUB_BILLING,
  ENABLE_EPISODES_OF_CARE,
  ENABLE_HUB_BILLING,
  GET_ONBOARDING_LINK,
  REMOVE_DEFAULT_ANSWERING_USER,
  RENAME_ORGANIZATION_INSURANCE_BILLING_CONFIGURATION,
  RESTORE_ORGANIZATION,
  SWAP_ORGANIZATION_INSURANCE_BILLING_CONFIGURATION,
  TOGGLE_CARE_PLANS,
  TOGGLE_MANAGE_SEATS_FOR_SUB_ORGS,
  UPDATE_INTERNAL_TOOLS_CONFIG,
  UPDATE_ONBOARDING_SURVEY,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_BILLING_CONFIGURATION,
  UPDATE_SEATS_FOR_SUB_ORGS,
  UPSERT_DOMAIN,
  UPSERT_MEMBER_DOMAIN_DESCRIPTION,
} from "./mutations";
import {
  GET_CONNECT_ONBOARDING_LINK,
  GET_CUSTOMER_PORTAL_LINK,
  GET_DASHBOARD_LINK,
  GET_DOMAINS_BY_ORGANIZATION,
  GET_IN_NETWORK_INSURANCE_ACCOUNT_TRANSACTIONS,
  GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS,
  GET_IN_NETWORK_INSURANCE_BILLING_PROVIDERS,
  GET_ONBOARDING_SURVEYS_BY_ORGANIZATION,
  GET_ORGANIZATION,
  GET_ORGANIZATION_BY_TOKEN,
  GET_ORGANIZATIONS,
} from "./queries";
import { usePagination } from "src/hooks/usePagination";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

export const useQueryOrganization = (organizationId: string) =>
  useQuery<{ organization: GetOrganizationResponse }, QueryOrganizationArgs>(
    GET_ORGANIZATION,
    {
      fetchPolicy: "network-only",
      variables: { organizationId },
      skip: !organizationId,
    }
  );

export const useQueryOnboardingOrganization = (token: string) =>
  useQuery<
    { organizationByOnboardingToken: GetOrganizationResponse },
    QueryOrganizationByOnboardingTokenArgs
  >(GET_ORGANIZATION_BY_TOKEN, {
    fetchPolicy: "network-only",
    variables: { token },
    context: {
      headers: {
        "Pear-Onboarding-Survey": token,
      },
    },
  });

export const useQueryGetOnboardingSurveys = (organizationId: string) =>
  useQuery<
    { onboardingSurveysByOrganizationId: GetOnboardingSurveysResponse },
    QueryOnboardingSurveysByOrganizationIdArgs
  >(GET_ONBOARDING_SURVEYS_BY_ORGANIZATION, {
    variables: { organizationId },
  });

export const useQueryInNetworkInsuranceBillingConfigurations = (
  organizationId: string,
  skip?: boolean
) =>
  useQuery<{
    inNetworkInsuranceBillingConfigurations: GetInsuranceBillingConfigurationsResponse;
  }>(GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS, {
    skip,
    variables: {
      organizationId,
    },
  });

export const useQueryInNetworkInsuranceBillingProviders = (skip?: boolean) =>
  useQuery<{ inNetworkBillingProviders: GetBillingProvidersResponse }>(
    GET_IN_NETWORK_INSURANCE_BILLING_PROVIDERS,
    { skip }
  );

export const useQueryInNetworkInsuranceAccountTransactions = (
  input: GetInsuranceAccountTransactionsInput
) =>
  usePagination<
    QueryInNetworkInsuranceAccountTransactionsArgs,
    { response: GetInsuranceAccountTransactionsResponse },
    InsuranceAccountTransaction
  >({
    document: GET_IN_NETWORK_INSURANCE_ACCOUNT_TRANSACTIONS,
    variables: { input },
  });

export const useLazyQueryCustomerPortalLink = (organizationId: string) =>
  useLazyQuery<{ customerPortalLink: GetUrlResponse }>(
    GET_CUSTOMER_PORTAL_LINK,
    { variables: { organizationId } }
  );

export const useLazyQueryConnectOnboardingLink = (organizationId: string) =>
  useLazyQuery<{ connectOnboardingLink: GetUrlResponse }>(
    GET_CONNECT_ONBOARDING_LINK,
    { variables: { organizationId } }
  );

export const useLazyQueryAccountDashboardLink = (organizationId: string) =>
  useLazyQuery<{ accountDashboardLink: GetUrlResponse }>(GET_DASHBOARD_LINK, {
    variables: { organizationId },
  });

export const usePaginatedQueryDomains = ({
  organizationId,
  pageSize = 10,
}: {
  organizationId: string;
  pageSize?: number;
}) =>
  usePagination<
    QueryDomainsByOrganizationIdArgs,
    { response: GetDomainsResponse },
    Domain
  >({
    document: GET_DOMAINS_BY_ORGANIZATION,
    variables: { organizationId },
    options: {
      size: pageSize,
    },
  });

export const useMutationUpdateOrganization = (organizationId?: string) =>
  useMutation<
    { updateOrganization: UpdateOrganizationResponse },
    MutationUpdateOrganizationArgs
  >(UPDATE_ORGANIZATION, {
    refetchQueries: [
      { query: GET_ORGANIZATION, variables: { organizationId } },
      GET_ORGANIZATIONS,
      GET_SUB_ORGS_BY_PARENT_ID,
    ],
  });

export const useMutationCreateOrganization = (userId: string) =>
  useMutation<
    { createOrganization: DefaultResponse },
    MutationCreateOrganizationArgs
  >(CREATE_ORGANIZATION, {
    refetchQueries: [
      { query: GET_ORGANIZATIONS },
      { query: GET_USER_AND_ORGS, variables: { userId: userId } },
      GET_SUB_ORGS_BY_PARENT_ID,
    ],
  });

export const useMutationAddDefaultAnsweringUser = () =>
  useMutation<
    { addDefaultAnsweringUser: DefaultResponse },
    MutationAddDefaultAnsweringUserArgs
  >(ADD_DEFAULT_ANSWERING_USER, {
    refetchQueries: [GET_ORGANIZATION],
  });

export const useMutationRemoveDefaultAnsweringUser = () =>
  useMutation<{ removeDefaultAnsweringUser: DefaultResponse }>(
    REMOVE_DEFAULT_ANSWERING_USER,
    {
      refetchQueries: [GET_ORGANIZATION],
    }
  );

export const useMutationDeleteOnboardingSurvey = () =>
  useMutation<{ deleteOnboardingSurvey: DefaultResponse }>(
    DELETE_ONBOARDING_SURVEY,
    {
      refetchQueries: [
        GET_ORGANIZATION,
        GET_ONBOARDING_SURVEYS_BY_ORGANIZATION,
      ],
    }
  );

export const useMutationOnboardingLink = () =>
  useMutation<{ getOnboardingLink: GetOnboardingLinkResponse }>(
    GET_ONBOARDING_LINK,
    {
      refetchQueries: [
        GET_ORGANIZATION,
        GET_ONBOARDING_SURVEYS_BY_ORGANIZATION,
      ],
    }
  );

export const useMutationUpdateOnboardingSurvey = () =>
  useMutation<{ updateOnboardingSurvey: GetOnboardingLinkResponse }>(
    UPDATE_ONBOARDING_SURVEY,
    {
      refetchQueries: [
        GET_ORGANIZATION,
        GET_ONBOARDING_SURVEYS_BY_ORGANIZATION,
      ],
    }
  );

export const useMutationCreateORUpdateOrganizationBillingConfiguration = () =>
  useMutation<
    { updateOrganizationBillingConfiguration: GetOrganizationResponse },
    MutationUpdateOrganizationBillingConfigurationArgs
  >(UPDATE_ORGANIZATION_BILLING_CONFIGURATION);

export const useMutationToggleEpisodesOfCare = () =>
  useMutation<
    { toggleEpisodesOfCare: GetOrganizationResponse },
    MutationToggleEpisodesOfCareArgs
  >(ENABLE_EPISODES_OF_CARE, {
    refetchQueries: [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationToggleCarePlans = () =>
  useMutation<
    { toggleCarePlans: GetOrganizationResponse },
    MutationToggleCarePlansArgs
  >(TOGGLE_CARE_PLANS, {
    refetchQueries: [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationToggleManageSeatsForSubOrgs = () =>
  useMutation<
    { toggleManageSeatsForSubOrgs: GetOrganizationResponse },
    MutationToggleManageSeatsForSubOrgsArgs
  >(TOGGLE_MANAGE_SEATS_FOR_SUB_ORGS, {
    refetchQueries: [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationUpdateSeatsForSubOrgs = () =>
  useMutation<
    { updateSeatsForSubOrgs: DefaultResponse },
    MutationUpdateSeatsForSubOrgsArgs
  >(UPDATE_SEATS_FOR_SUB_ORGS, {
    refetchQueries: [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationDeleteOrganization = () =>
  useMutation<
    { deleteOrganization: GetOrganizationResponse },
    MutationDeleteOrganizationArgs
  >(DELETE_ORGANIZATION);

export const useMutationRestoreOrganization = () =>
  useMutation<{ restoreOrganization: GetOrganizationResponse }>(
    RESTORE_ORGANIZATION
  );

export const useMutationCreateOrUpdateOrganizationInsuranceBillingConfiguration =
  () =>
    useMutation<
      {
        createOrUpdateOrganizationInsuranceBillingConfiguration: GetOrganizationResponse;
      },
      MutationCreateOrUpdateOrganizationInsuranceBillingConfigurationArgs
    >(CREATE_OR_UPDATE_ORGANIZATION_INSURANCE_BILLING_CONFIGURATION);

export const useMutationCreateOrUpdateInNetworkInsuranceBillingConfiguration =
  () =>
    useMutation<
      {
        createOrUpdateInNetworkInsuranceBillingConfiguration: GetInsuranceBillingConfigurationsResponse;
      },
      MutationCreateOrUpdateInNetworkInsuranceBillingConfigurationArgs
    >(CREATE_OR_UPDATE_IN_NETWORK_INSURANCE_CONFIGURATION, {
      refetchQueries: [GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS],
    });

// When these hooks are used in network mode
// They will refetch the in network insurance billing configurations
// which are used to update the connect insurance configurations in organizations

export const useMutationCreateOrUpdateBillingProvider = (inNetwork = false) =>
  useMutation<
    { createOrUpdateBillingProvider: GetBillingProviderResponse },
    MutationCreateOrUpdateBillingProviderArgs
  >(CREATE_OR_UPDATE_BILLING_PROVIDER, {
    refetchQueries: inNetwork
      ? [GET_IN_NETWORK_INSURANCE_BILLING_PROVIDERS]
      : [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationDeleteBillingProvider = (inNetwork = false) =>
  useMutation<
    { deleteBillingProvider: DefaultResponse },
    MutationDeleteBillingProviderArgs
  >(DELETE_BILLING_PROVIDER, {
    refetchQueries: inNetwork
      ? [GET_IN_NETWORK_INSURANCE_BILLING_PROVIDERS]
      : [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationDeleteInsuranceBillingConfiguration = (
  inNetwork = false
) =>
  useMutation<
    { deleteInsuranceBillingConfiguration: DefaultResponse },
    MutationDeleteInsuranceBillingConfigurationArgs
  >(DELETE_INSURANCE_BILLING_CONFIGURATION, {
    refetchQueries: inNetwork
      ? [GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS]
      : [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationCreateOrUpdateCostConfigurations = (
  inNetwork = false
) =>
  useMutation<
    { createOrUpdateCostConfigurations: GetOrganizationResponse },
    MutationCreateOrUpdateCostConfigurationsArgs
  >(CREATE_OR_UPDATE_COST_CONFIGURATIONS, {
    refetchQueries: inNetwork
      ? [GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS]
      : [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationDeleteCostConfiguration = (inNetwork = false) =>
  useMutation<
    { deleteCostConfiguration: GetOrganizationResponse },
    MutationDeleteCostConfigurationArgs
  >(DELETE_COST_CONFIGURATION, {
    refetchQueries: inNetwork
      ? [GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS]
      : [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationRenameOrganizationInsuranceBillingConfiguration = () =>
  useMutation<
    {
      renameOrganizationInsuranceBillingConfiguration: GetOrganizationResponse;
    },
    MutationRenameOrganizationInsuranceBillingConfigurationArgs
  >(RENAME_ORGANIZATION_INSURANCE_BILLING_CONFIGURATION, {
    refetchQueries: [GET_MEMBERS],
  });

export const useMutationSwapOrganizationInsuranceBillingConfiguration = () =>
  useMutation<
    { swapOrganizationInsuranceBillingConfiguration: GetOrganizationResponse },
    MutationSwapOrganizationInsuranceBillingConfigurationArgs
  >(SWAP_ORGANIZATION_INSURANCE_BILLING_CONFIGURATION, {
    refetchQueries: [GET_MEMBERS],
  });

export const useMutationEnableHubBilling = (organizationId: string) =>
  useMutation<
    { enableHubBilling: GetOrganizationResponse },
    MutationEnableHubBillingArgs
  >(ENABLE_HUB_BILLING, { variables: { organizationId } });

export const useMutationDisableHubBilling = (organizationId: string) =>
  useMutation<
    { disableHubBilling: DefaultResponse },
    MutationDisableHubBillingArgs
  >(DISABLE_HUB_BILLING, { variables: { organizationId } });

export const useMutationCreateDomain = () =>
  useMutation<{ createDomain: DomainResponse }, MutationCreateDomainArgs>(
    CREATE_DOMAIN,
    {
      refetchQueries: [GET_ORGANIZATION],
    }
  );

export const useMutationUpsertDomain = () =>
  useMutation<{ upsertDomain: DomainResponse }, MutationUpsertDomainArgs>(
    UPSERT_DOMAIN,
    {
      refetchQueries: [GET_ORGANIZATION, GET_DOMAINS_BY_ORGANIZATION],
    }
  );

export const useMutationUpsertMemberDomainDescription = () =>
  useMutation<
    { upsertDomainDescription: DefaultResponse },
    MutationUpsertDomainDescriptionArgs
  >(UPSERT_MEMBER_DOMAIN_DESCRIPTION, {
    refetchQueries: [GET_DOMAINS_BY_ORGANIZATION],
  });

export const useMutationDeleteDomain = ({
  domainId,
  organizationId,
}: {
  domainId: string;
  organizationId: string;
}) =>
  useMutation<{ deleteDomain: DefaultResponse }>(DELETE_DOMAIN, {
    refetchQueries: [GET_DOMAINS_BY_ORGANIZATION],
    variables: { domainId, organizationId },
  });

export const useMutationUpdateInternalToolsConfig = () =>
  useMutation<
    { updateInternalToolsConfig: DefaultResponse },
    MutationUpdateInternalToolsConfigArgs
  >(UPDATE_INTERNAL_TOOLS_CONFIG, {
    update(cache, { data }, { variables }) {
      if (!variables || !data?.updateInternalToolsConfig.success) return;

      const { hubspotTicketId, organizationId } = variables.input;

      cache.updateQuery(
        { query: GET_ORGANIZATION, variables: { organizationId } },
        (data) => ({
          ...data,
          organization: {
            ...data.organization,
            data: {
              ...data.organization.data,
              hubspotTicketId,
            },
          },
        })
      );
    },
  });
