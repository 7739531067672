import { ActionIcon, Grid, Group, Radio } from "@mantine/core";
import { ArrayHelpers, FieldArray, useFormikContext } from "formik";
import { PhoneNumberType } from "src/graphql";
import { StyledLabel } from "./StyledLabel";
import {
  MemberFormPhoneNumber,
  MemberFormValues,
} from "src/pages/members/MemberFormFormik";
import { FormikInput } from "./FormikInput";
import { v4 } from "uuid";
import { Trash2 } from "react-feather";
import { FormikCheckbox } from "./FormikCheckbox";
import { FormikSelect } from "./FormikSelect";
import {
  getEnumStringValues,
  wrapSelectOption,
  wrapSelectOptionsArray,
} from "src/utils";

export const makeEmptyFormPhoneNumber = (
  isPrimary = false
): MemberFormPhoneNumber => ({
  _id: v4(),
  digits: "",
  doNotCall: false,
  type: wrapSelectOption(PhoneNumberType.Mobile),
  isPrimary,
});

type FormikMemberPhoneNumbersInputProps = {
  parentFieldName: string;
  helpersRef: React.MutableRefObject<ArrayHelpers | null>;
};

export const FormikMemberPhoneNumbersInput = ({
  parentFieldName,
  helpersRef,
}: FormikMemberPhoneNumbersInputProps) => {
  const { values, setValues } = useFormikContext<MemberFormValues>();

  const handleSelectPrimary = (nextPrimaryId: string) => {
    setValues({
      ...values,
      contactInfo: {
        ...values.contactInfo,
        phoneNumbers: values.contactInfo.phoneNumbers.map((p) => ({
          ...p,
          isPrimary: p._id === nextPrimaryId,
        })),
      },
    });
  };

  const phoneNumbers = values.contactInfo.phoneNumbers;

  return (
    <FieldArray
      name={parentFieldName}
      render={(helpers) => {
        helpersRef.current = helpers;

        return (
          <Grid>
            <Grid.Col sm={4} pb={4}>
              <StyledLabel required>Phone Number(s)</StyledLabel>
            </Grid.Col>
            <Grid.Col sm={8} pb={4}>
              <StyledLabel>Type</StyledLabel>
            </Grid.Col>
            {phoneNumbers.map((phoneNumber, i) => {
              return (
                <>
                  <Grid.Col sm={4} pt={0}>
                    <FormikInput
                      type="tel"
                      name={`${parentFieldName}.${i}.digits`}
                      placeholder="Digits"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col sm={4} pt={0}>
                    <FormikSelect
                      name={`${parentFieldName}.${i}.type`}
                      options={wrapSelectOptionsArray(
                        getEnumStringValues(PhoneNumberType)
                      )}
                    />
                  </Grid.Col>

                  <Grid.Col sm={4} pt={0}>
                    <Group align="center" h="40px">
                      <Radio
                        label="Primary"
                        size="xs"
                        onChange={() =>
                          !phoneNumber.isPrimary &&
                          handleSelectPrimary(phoneNumber._id)
                        }
                        checked={phoneNumber.isPrimary}
                      />

                      <FormikCheckbox
                        name={`${parentFieldName}.${i}.doNotCall`}
                        label="Do Not Call"
                        size="xs"
                        mt=".25em"
                      />

                      <ActionIcon
                        color="red"
                        title="Delete Phone Number"
                        onClick={() => helpers.remove(i)}
                        disabled={
                          phoneNumbers.length <= 1 || phoneNumber.isPrimary
                        }
                      >
                        <Trash2 size="16" />
                      </ActionIcon>
                    </Group>
                  </Grid.Col>
                </>
              );
            })}
          </Grid>
        );
      }}
    />
  );
};
