import { PreviewContainer, PreviewHeader } from "./shared";
import { ReferralTemplate } from "src/graphql";
import { sanitizeHtml } from "src/utils/htmlParser";

// TODO: should these previews have a bit more meat, perhaps about the activity it
//       schedules?

type ReferralTemplatePreviewProps = {
  referralTemplate: ReferralTemplate;
};

export const ReferralTemplatePreview = ({
  referralTemplate,
}: ReferralTemplatePreviewProps) => {
  return (
    <PreviewContainer>
      <PreviewHeader>Preview</PreviewHeader>
      {!referralTemplate && "<missing template info>"}

      {referralTemplate && (
        <div style={{ padding: 3 }}>
          {/* Title */}
          <div style={{ marginTop: "0.25em" }}>{referralTemplate.title}</div>

          {/* Description */}
          <div
            style={{ marginTop: "0.5em" }}
            dangerouslySetInnerHTML={{
              __html:
                sanitizeHtml(referralTemplate.description) ??
                "<missing template description>",
            }}
          />
        </div>
      )}
    </PreviewContainer>
  );
};
