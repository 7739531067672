import {
  ActionIcon,
  Button,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { PhoneOff, PhoneCall, MicOff, Mic, Plus } from "react-feather";
import useTwilio from "src/hooks/useTwilio";
import { AddParticipantModal } from "./AddParticipantModal";

const NUMBER_PADS = [
  { main: "1" },
  { main: "2", sub: "A B C" },
  { main: "3", sub: "D E F" },

  { main: "4", sub: "G H I" },
  { main: "5", sub: "J K L" },
  { main: "6", sub: "M N O" },

  { main: "7", sub: "P Q R S" },
  { main: "8", sub: "T U V" },
  { main: "9", sub: "W X Y Z" },

  { main: "*" },
  { main: "0" },
  { main: "#" },
];

type NumberPadProps = {
  selectedNumberDigits: string;
  doNotCall?: boolean;
};

export const NumberPad = ({
  selectedNumberDigits,
  doNotCall,
}: NumberPadProps) => {
  const {
    isCallInProgress,
    isSettingDevice,
    makeOutgoingCall,
    endOutgoingCall,
    endIncomingCall,
    incomingCall,
    twilioCall,
    isIncomingCallInProgress,
    micState,
  } = useTwilio();

  const [addParticipantModalOpen, setAddParticipantModalOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(twilioCall?.isMuted() || false);

  useEffect(() => {
    twilioCall?.on("mute", (isMuted: boolean) => {
      setIsMuted(isMuted);
    });
  }, [twilioCall]);

  useEffect(() => {
    if (!twilioCall && !incomingCall) {
      setIsMuted(false);
    }
  }, [incomingCall, twilioCall]);

  return (
    <>
      <Stack px="md">
        {incomingCall && (
          <Button
            color="red"
            fullWidth
            onClick={() => {
              endIncomingCall();
            }}
          >
            <PhoneOff size={18} />
          </Button>
        )}
        {!incomingCall && !isCallInProgress && micState !== "denied" && (
          <Button
            fullWidth
            disabled={isSettingDevice || doNotCall}
            onClick={() => makeOutgoingCall(selectedNumberDigits)}
          >
            <PhoneCall size={18} />
          </Button>
        )}
        {!incomingCall && !isCallInProgress && micState === "denied" && (
          <>
            <p>
              Access to your microphone is currently disabled. For information
              on how to change this setting, please visit{" "}
              <a
                href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop"
                target="_blank"
                rel="noreferrer"
              >
                this help article
              </a>
              .
            </p>
            <p>Once this setting has been changed, please reload this page.</p>
          </>
        )}
        {!incomingCall && isCallInProgress && (
          <Group>
            <Button
              color="red"
              style={{ flexGrow: 1 }}
              onClick={() => {
                endOutgoingCall();
              }}
            >
              <PhoneOff size={18} />
            </Button>

            {(isCallInProgress || isIncomingCallInProgress) && isMuted && (
              <ActionIcon onClick={() => twilioCall?.mute(false)}>
                <Tooltip label="Mic is off" position="top-start">
                  <MicOff size={18} />
                </Tooltip>
              </ActionIcon>
            )}
            {(isCallInProgress || isIncomingCallInProgress) && !isMuted && (
              <ActionIcon onClick={() => twilioCall?.mute(true)}>
                <Tooltip label="Mic is on" position="top-start">
                  <Mic size={18} />
                </Tooltip>
              </ActionIcon>
            )}
            {(isCallInProgress || isIncomingCallInProgress) && (
              <ActionIcon onClick={() => setAddParticipantModalOpen(true)}>
                <Tooltip label="Add participants" position="top-start">
                  <Plus size={18} />
                </Tooltip>
              </ActionIcon>
            )}
          </Group>
        )}

        {twilioCall && (
          <SimpleGrid cols={3}>
            {NUMBER_PADS.map(({ main, sub }) => (
              <Button
                key={main}
                variant="light"
                onClick={() => twilioCall?.sendDigits(main)}
                leftIcon={<Text weight={600}>{main}</Text>}
              >
                <Text w="7ch">{sub}</Text>
              </Button>
            ))}
          </SimpleGrid>
        )}
      </Stack>

      <AddParticipantModal
        open={addParticipantModalOpen}
        onClose={() => setAddParticipantModalOpen(false)}
      />
    </>
  );
};
