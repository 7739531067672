import { Button, Group, Stack, Text, TextInput, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import toast from "src/libs/toast";
import { isValidPhoneNumber } from "react-phone-number-input";

import MembersTable from "src/components/members-table/MembersTable";
import { Modal, ModalBody, ModalHeader } from "src/components/modal";
import { ExternalContact, Member } from "src/graphql";
import { useAuthContext } from "src/hooks";
import useTwilio from "src/hooks/useTwilio";
import { formatPhoneAsE164 } from "src/utils/phone";
import ExternalContactsTable from "src/components/external-contacts-table/ExternalContactsTable";

export const SelectContext = () => {
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [goButtonEnabled, setGoButtonEnabled] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showExternalContactModal, setShowExternalContactModal] =
    useState(false);
  const { widget: twilioWidget } = useTwilio();
  const { selectedOrganizationId } = useAuthContext();

  useEffect(() => {
    const isValid = isValidPhoneNumber(phone ?? "", { defaultCountry: "US" });
    setGoButtonEnabled(isValid);
  }, [phone]);

  const handleSelectMember = (member: Member) => {
    twilioWidget.selectMember(member);
    setShowMemberModal(false);
  };

  const handleSelectExternalContact = (contact: ExternalContact) => {
    twilioWidget.selectPhoneNumber(contact.phone ?? "");
    setShowExternalContactModal(false);
  };

  const handleSubmitPhoneNumber = () => {
    const formattedPhoneNumber = formatPhoneAsE164(phone || "");

    if (!formattedPhoneNumber) {
      toast.error("Phone Number is invalid");
      return;
    }

    twilioWidget.selectPhoneNumber(formattedPhoneNumber);
  };

  return (
    <Stack mih={450} align="center" justify="center">
      <Title order={4}>Call Center </Title>

      <Stack spacing="xs" align="center" justify="center" w="100%">
        <Button onClick={() => setShowMemberModal(true)}>Select Member</Button>
        <Button onClick={() => setShowExternalContactModal(true)}>
          Select External Contact
        </Button>

        <Text>OR</Text>

        <form onSubmit={() => handleSubmitPhoneNumber()}>
          <Group spacing="xs">
            <TextInput
              styles={{ root: { flexGrow: 1 } }}
              placeholder="Enter a Phone Number"
              onChange={(e) => setPhone(e.target.value.trim())}
            />

            <Button disabled={!goButtonEnabled} type="submit">
              Go
            </Button>
          </Group>
        </form>
      </Stack>

      <Modal opened={showMemberModal} onClose={() => setShowMemberModal(false)}>
        <ModalHeader onClose={() => setShowMemberModal(false)}>
          Select Member
        </ModalHeader>

        <ModalBody noFooter>
          <MembersTable
            organizationIds={[selectedOrganizationId]}
            noSelect
            noFirstName
            noLastName
            noEmail
            noGroups
            noDOB
            noMedicaidStatus
            noMedicareStatus
            noEHRType
            noPrimaryInsurance
            noSecondaryInsurance
            noRecommendationStatus
            noNotes
            noFile
            noBatchID
            onMemberSelect={handleSelectMember}
          />
        </ModalBody>
      </Modal>

      <Modal
        opened={showExternalContactModal}
        onClose={() => setShowExternalContactModal(false)}
      >
        <ModalHeader onClose={() => setShowExternalContactModal(false)}>
          Select External Contact
        </ModalHeader>

        <ModalBody noFooter>
          <ExternalContactsTable
            organizationId={selectedOrganizationId}
            onSelect={handleSelectExternalContact}
          />
        </ModalBody>
      </Modal>
    </Stack>
  );
};
