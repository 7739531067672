import { DATE_TIME_FORMAT } from "src/constants/common";
import { FrequencyInterval } from "src/graphql";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import log from "loglevel";

dayjs.extend(utc);
dayjs.extend(timezone);

export const getFormattedDate = (
  timestamp: string | undefined,
  includeTime = false,
  greenwich = false
) => {
  // dayjs built in timezone detection
  const userTimezone = greenwich ? "Etc/GMT-0" : dayjs.tz.guess();
  const mDate = dayjs(timestamp).tz(userTimezone);
  return includeTime
    ? mDate.format("M/D/YYYY h:mm A")
    : mDate.format("M/D/YYYY");
};

export const getFormattedFrequency = (
  frequency: number | undefined,
  frequencyInterval: FrequencyInterval | undefined
) => {
  if (frequencyInterval === FrequencyInterval.AsNeeded) {
    return "As Needed";
  } else if (frequency === 1) {
    switch (frequencyInterval) {
      case FrequencyInterval.Hour:
        return "Hourly";
      case FrequencyInterval.Day:
        return "Daily";
      case FrequencyInterval.Week:
        return "Weekly";
      case FrequencyInterval.Month:
        return "Monthly";
      case FrequencyInterval.Year:
        return "Yearly";
    }
  }
  if (frequency && frequencyInterval) {
    return `Every ${frequency} ${frequencyInterval}${frequency > 1 ? "s" : ""}`;
  }
  return "";
};
/**
 * Don't lean on this, I have not validated the regex -- just pulling in as a helper util
 * for seeking out non-ISO dates in the dataset when Moment throws the big console warning
 *
 * regex from: https://stackoverflow.com/questions/52869695/check-if-a-date-string-is-in-iso-and-utc-format
 */
export const logIfNotISO = (dateString: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(dateString))
    log.log("Probably not an ISO date: ", dateString);

  const date = new Date(dateString);
  if (date.toISOString() !== dateString) {
    log.log("Probably not an ISO date string:");
  }
};

export const formatDateTime = (input: string) =>
  dayjs(input).format(DATE_TIME_FORMAT);

export const parseDate = (dateString: string) =>
  dayjs(dateString).format("M/D/YYYY h:mm A");

export const formatDuration = (seconds: number) =>
  dayjs.duration(seconds, "seconds").format("HH:mm:ss");
