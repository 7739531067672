import { gql } from "@apollo/client";
import { ActivityTemplateFields } from "./types";
import { questionFields } from "../Question/types";

export const GET_ACTIVITY_TEMPLATES = gql`
  query GetActivityTemplates($organizationId: ID!,$includeArchived:Boolean) {
    activityTemplates(organizationId: $organizationId,includeArchived:$includeArchived) {
      success
      message
      data {
        accessType
        activityTemplate {
          ${ActivityTemplateFields}
        }
      }
    }
  }
`;

export const GET_ACTIVITY_TEMPLATE = gql`
  query GetActivityTemplate($activityTemplateId: ID!, $organizationId: ID!) {
    activityTemplate(activityTemplateId: $activityTemplateId, organizationId: $organizationId) {
      success
      message
      data {
        accessType
        activityTemplate { 
          ${ActivityTemplateFields} 
        }
      }
    }
  }
`;

export const GET_ACTIVITY_TEMPLATE_QUESTIONS = gql`
  query GetActivityTemplateQuestions($organizationId: ID!, $activityTemplateId: ID!) {
    getActivityTemplateQuestions(organizationId: $organizationId, activityTemplateId: $activityTemplateId) {
      success
      message
      data {
        ${questionFields}
      }
    }
  }
`;
