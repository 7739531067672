import { LockingReferenceFields } from "../Common/types";

export const ActivityTemplateFields = `
  _id
  title
  description
  organization
  billable
  isActive
  
  syncAppointment
  appointmentDetails {
    locationType
    locationFacility
    locationDepartment
    locationRoom
  }

  defaultBillingDetails {
    placeOfService
    procedure
    modifier
    diagnosisCodes
  }

  tasks {
    id
    formId
    description
    type
  }

  lockingReferences {
    ${LockingReferenceFields}
  }
`;

export const ActivityTemplateFieldsMin = `
  _id
  title
  description
  organization
  billable
  lockingReferences {
    ${LockingReferenceFields}
  }
`;
