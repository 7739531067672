import { LockingReferenceFields } from "../Common/types";

export const carePathwayTemplateMinimalFields = `
_id
name
isDefault
updatedAt
createdAt
organizationId
completedPercentage
relatedMemberCarePathwayId
relatedMemberCarePathwayCreatedAt
status
userId
lockedByUse
steps {
  _id
  scheduleType
  date
  interval
  activityTemplateId
  assignedUserType
  assignedUserTypeId
}
lockingReferences {
  ${LockingReferenceFields}
}
defaultAssignedUserGroupId
defaultAssignedUserGroup {
  _id
  title
}
accessType
familyId
version
`;

export const carePathwayTemplateMinimalFieldsForFlowBuilder = `
_id
name
isDefault
updatedAt
createdAt
organizationId
lockedByUse
lockingReferences {
  ${LockingReferenceFields}
}
defaultAssignedUserGroupId
defaultAssignedUserGroup {
  _id
  title
}
accessType
familyId
version
`;

export const carePathwayFields = `
_id
carePathwayTemplateId
memberId
inProgressStepIds
completedStepIds
status
name
carePathwayTemplate {
  name
}
stepActivities {
  stepId
  activityTemplateId
  id
  date
  status
}
`;

export const StepsFields = `_id
isMultiOutput
_id
scheduleType
date
interval
activityTemplateId
assignedUserType
assignedUserTypeId
isAutoGenerated
position {
  x
  y
}
nextStepsConditions {
  _id
  conditionsGroup {
   _id
   conditionOperator
   conditions {
    _id
    operator
    conditionOperator
    value
    dataId
    }
  }
}

targetSteps {
  _id
  sourceHandle
  targetStepId
  targetHandle
  conditionsGroupItemId
}
`;

export const carePathwayTemplateFields = `
_id
name
firstStepId
isDefault
updatedAt
createdAt
organizationId  
userId
steps { 
  ${StepsFields}
}
lockedByUse
lockingReferences {
  ${LockingReferenceFields}
}
accessType
`;
