import {
  Medication,
  useMutationDeleteMedication,
  useMutationUpsertMedication,
} from "src/graphql";
import toast from "src/libs/toast";
import { formatMedicationForSave } from "src/utils";

export const useMedicationMutations = () => {
  const [mutationUpsertMedication, { loading: upsertLoading }] =
    useMutationUpsertMedication();
  const [mutationDeleteMedication, { loading: deleteLoading }] =
    useMutationDeleteMedication();

  const saveMedication = async (
    values: Medication,
    organizationId: string,
    onSuccess?: () => void
  ) => {
    try {
      const formattedValues = formatMedicationForSave(values);

      const response = await mutationUpsertMedication({
        variables: {
          input: {
            ...formattedValues,
            organizationId,
          },
        },
      });

      if (!response.data?.upsertMedication.success) {
        throw new Error(response.data?.upsertMedication.message);
      }

      toast.success("Medication saved successfully");
      onSuccess?.();
      return true;
    } catch (error) {
      const defaultErrorMessage = "Failed to save medication";

      toast.error(
        error instanceof Error
          ? (error.message ?? defaultErrorMessage)
          : defaultErrorMessage
      );
      return false;
    }
  };

  const deleteMedication = async (
    medicationId: string,
    organizationId: string,
    onSuccess?: () => void
  ) => {
    try {
      const response = await mutationDeleteMedication({
        variables: {
          id: medicationId,
          organizationId,
        },
      });

      if (!response.data?.deleteMedication.success) {
        throw new Error(response.data?.deleteMedication.message);
      }

      toast.success("Medication deleted successfully");
      onSuccess?.();
      return true;
    } catch (error) {
      const defaultErrorMessage = "Failed to delete medication";

      toast.error(
        error instanceof Error
          ? (error.message ?? defaultErrorMessage)
          : defaultErrorMessage
      );
      return false;
    }
  };

  return {
    saveMedication,
    deleteMedication,
    isSaving: upsertLoading,
    isDeleting: deleteLoading,
  };
};
