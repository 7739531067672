import {
  EducationLevel,
  EmploymentStatus,
  EnrollmentStatus,
  Ethnicity,
  Gender,
  Language,
  MaritalStatus,
  MemberFieldState,
  MemberProfileSettings,
  PhoneNumberType,
  PregnancyStatus,
  Pronouns,
  Sex,
  SexualOrientation,
} from "src/graphql";
import { getEnumStringValues, ySelectOptionSchema } from "src/utils";

import * as Yup from "yup";

const races = [
  "Alaska Native",
  "Native American/Indigenous American/American Indian",
  "Non-Hawaiian Pacific Islander",
  "Hispanic / Latino / Spanish",
  "American Indian / Alaskan",
  "Asian / Asian American",
  "Native Hawaiian",
  "Black / African American",
  "Caucasian / White",
  "Not Listed",
  "Prefer Not To Disclose",
];

export const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const MemberPhoneNumberSchema = Yup.object().shape({
  digits: Yup.string()
    .matches(phoneRegex, "Invalid Phone number")
    .required("Required"),
  type: ySelectOptionSchema(
    Yup.string().oneOf(getEnumStringValues(PhoneNumberType))
  ).required(),
  doNotCall: Yup.boolean().required(),
  isPrimary: Yup.boolean().required(),
});

export const getMembersSchema = (
  memberProfileSettings: MemberProfileSettings
) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .min(2, "Name must be longer than 2 characters")
      .max(50, "Name must be shorter than 50 characters")
      .required("Required"),
    lastName: Yup.string()
      .trim()
      .min(2, "Name must be longer than 2 characters")
      .max(50, "Name must be shorter than 50 characters")
      .required("Required"),
    phonetic:
      memberProfileSettings.memberPhoneticState === MemberFieldState.Required
        ? Yup.string().required("Name pronunciation is required")
        : Yup.string().nullable(true),
    preferredName:
      memberProfileSettings.memberPreferredNameState ===
      MemberFieldState.Required
        ? Yup.string().required("Preferred name is required")
        : Yup.string().nullable(true),
    nickName: Yup.string()
      .trim()
      .min(2, "Name must be longer than 2 characters")
      .max(50, "Name must be shorter than 50 characters")
      .nullable(true),
    medicareStatus: ySelectOptionSchema(
      Yup.string().oneOf(getEnumStringValues(EnrollmentStatus))
    ).default(undefined),
    medicaidStatus: ySelectOptionSchema(
      Yup.string().oneOf(getEnumStringValues(EnrollmentStatus))
    ).default(undefined),
    dob:
      memberProfileSettings.memberDOBState === MemberFieldState.Required
        ? Yup.string().required("Date of birth is required")
        : Yup.string().nullable(true),
    gender:
      memberProfileSettings.memberGenderState === MemberFieldState.Required
        ? Yup.array(
            ySelectOptionSchema(Yup.string().oneOf(getEnumStringValues(Gender)))
          )
            .min(1, "Gender is required")
            .required("Gender is required")
        : Yup.array(
            ySelectOptionSchema(
              Yup.string().oneOf(getEnumStringValues(Gender)).nullable(true)
            )
          ),
    sexualOrientation:
      memberProfileSettings.memberSexualOrientationState ===
      MemberFieldState.Required
        ? Yup.array(
            ySelectOptionSchema(
              Yup.string().oneOf(getEnumStringValues(SexualOrientation))
            )
          )
            .min(1, "Sexual Orientation is required")
            .required("Sexual Orientation are required")
        : Yup.array(
            ySelectOptionSchema(
              Yup.string()
                .oneOf(getEnumStringValues(SexualOrientation))
                .nullable(true)
            )
          ),
    ethnicity:
      memberProfileSettings.memberEthnicityState === MemberFieldState.Required
        ? ySelectOptionSchema(
            Yup.string().oneOf(getEnumStringValues(Ethnicity)).required()
          )
            .strict(true)
            .required("Ethnicity is required")
        : ySelectOptionSchema(
            Yup.string().oneOf(getEnumStringValues(Ethnicity))
          )
            .strict(true)
            .nullable(),
    spokenLanguages:
      memberProfileSettings.memberLanguagesState === MemberFieldState.Required
        ? Yup.array(
            ySelectOptionSchema(
              Yup.string().oneOf(getEnumStringValues(Language))
            )
          )
            .min(1, "Language is required")
            .required("Language is required")
        : Yup.array(
            ySelectOptionSchema(
              Yup.string().oneOf(getEnumStringValues(Language)).nullable(true)
            )
          ),
    pronouns:
      memberProfileSettings.memberPronounsState === MemberFieldState.Required
        ? Yup.array(
            ySelectOptionSchema(
              Yup.string().oneOf(getEnumStringValues(Pronouns))
            )
          )
            .min(1, "Pronouns are required")
            .required("Pronouns are required")
        : Yup.array(
            ySelectOptionSchema(
              Yup.string().oneOf(getEnumStringValues(Pronouns)).nullable(true)
            )
          ),
    race:
      memberProfileSettings.memberRaceState === MemberFieldState.Required
        ? Yup.array(ySelectOptionSchema(Yup.string().oneOf(races)))
            .min(1, "Race is required")
            .required("Race is required")
        : Yup.array(
            ySelectOptionSchema(Yup.string().oneOf(races)).nullable(true)
          ),
    socialSecurityNumber:
      memberProfileSettings.memberSocialSecurityNumberState ===
      MemberFieldState.Required
        ? Yup.string()
            .min(9, "SSN must be at least 9 characters")
            .required("SSN is required")
        : Yup.string().min(9, "SSN must be at least 9 characters").nullable(),
    sex:
      memberProfileSettings.memberSexState === MemberFieldState.Required
        ? ySelectOptionSchema(
            Yup.string().oneOf(getEnumStringValues(Sex)).required()
          )
            .strict(true)
            .required("Sex is required")
        : ySelectOptionSchema(Yup.string().oneOf(getEnumStringValues(Sex)))
            .strict(true)
            .nullable(),
    primaryCIN: Yup.string().nullable(true),
    primaryInsuranceCompany:
      memberProfileSettings.memberPrimaryInsuranceState ===
      MemberFieldState.Required
        ? ySelectOptionSchema(Yup.string().required())
            .strict(true)
            .required("Primary Insurance Company is required")
        : ySelectOptionSchema(Yup.string().required())
            .strict(true)
            .nullable(true),
    secondaryCIN: Yup.string().nullable(true),
    secondaryInsuranceCompany:
      memberProfileSettings.memberSecondaryInsuranceState ===
      MemberFieldState.Required
        ? ySelectOptionSchema(Yup.string().required())
            .strict(true)
            .required("Secondary Insurance Company is required")
        : ySelectOptionSchema(Yup.string().required())
            .strict(true)
            .nullable(true),
    contactInfo: Yup.object().shape({
      email: Yup.string().trim().email("Invalid email").nullable(true),
      address:
        memberProfileSettings.memberAddressState === MemberFieldState.Required
          ? Yup.string().trim().required("Address is required")
          : Yup.string().trim().nullable(true),
      address2: Yup.string().trim().nullable(true),
      city:
        memberProfileSettings.memberAddressState === MemberFieldState.Required
          ? Yup.string().trim().required("City is required")
          : Yup.string().trim().nullable(true),
      state: Yup.mixed().when(["country"], {
        is: (country: { value: string } | undefined) => country?.value === "US",
        then: () =>
          memberProfileSettings.memberAddressState === MemberFieldState.Required
            ? ySelectOptionSchema(Yup.string().required())
                .strict(true)
                .required("State is required")
            : ySelectOptionSchema(Yup.string().required())
                .strict(true)
                .nullable(true),
        otherwise: () =>
          memberProfileSettings.memberAddressState === MemberFieldState.Required
            ? Yup.string().required("State is required")
            : Yup.string().nullable(true),
      }),
      country:
        memberProfileSettings.memberAddressState === MemberFieldState.Required
          ? ySelectOptionSchema(Yup.string().required())
              .strict(true)
              .required("Country is required")
          : ySelectOptionSchema(Yup.string().required())
              .strict(true)
              .nullable(true),
      zip:
        memberProfileSettings.memberAddressState === MemberFieldState.Required
          ? Yup.string()
              .trim()
              .max(30, "Max length of character should be 30")
              .required("Zip is required")
          : Yup.string()
              .trim()
              .max(30, "Max length of character should be 30")
              .nullable(true),
      phoneNumbers: Yup.array(MemberPhoneNumberSchema)
        .min(1, "Must pass at least 1 valid Phone Number")
        .required(),
      groups:
        memberProfileSettings.memberOrgGroupsState === MemberFieldState.Required
          ? Yup.array(ySelectOptionSchema(Yup.string()))
              .min(1, "Group is required")
              .required("Group is required")
          : Yup.array(ySelectOptionSchema(Yup.string()).nullable(true)),
    }),
    ehrID: Yup.string().nullable(true),
    ehrIDType: ySelectOptionSchema(Yup.string()).default(undefined),
    peopleInHousehold:
      memberProfileSettings.memberPeopleInHouseholdState ===
      MemberFieldState.Required
        ? Yup.number()
            .required()
            .min(0, "Number of People in Household must be at least 0")
        : Yup.number()
            .nullable(true)
            .min(0, "Number of People in Household must be at least 0"),
    childrenInHousehold:
      memberProfileSettings.memberChildrenInHouseholdState ===
      MemberFieldState.Required
        ? Yup.number()
            .required()
            .min(0, "Number of Children in Household must be at least 0")
        : Yup.number()
            .nullable(true)
            .min(0, "Number of Children in Household must be at least 0"),
    pregnancyStatusEnum:
      memberProfileSettings.memberPregnancyState === MemberFieldState.Required
        ? ySelectOptionSchema(
            Yup.string().oneOf(getEnumStringValues(PregnancyStatus)).required()
          )
            .strict(true)
            .required("Pregnancy Status is required")
        : ySelectOptionSchema(
            Yup.string().oneOf(getEnumStringValues(PregnancyStatus))
          )
            .strict(true)
            .nullable(),
    maidenNameOrAlias:
      memberProfileSettings.memberMaidenNameOrAliasState ===
      MemberFieldState.Required
        ? Yup.string().trim().required("Maiden Name or Alias is required")
        : Yup.string().trim().nullable(true),

    medicalRecordNumber:
      memberProfileSettings.memberMedicalRecordNumberState ===
      MemberFieldState.Required
        ? Yup.string().trim().required("Medical Record Number is required")
        : Yup.string().trim().nullable(true),
    highestLevelOfEducation:
      memberProfileSettings.memberHighestLevelOfEducationState ===
      MemberFieldState.Required
        ? ySelectOptionSchema(
            Yup.string().oneOf(getEnumStringValues(EducationLevel)).required()
          )
            .strict(true)
            .required("Highest Level of Education is required")
        : ySelectOptionSchema(
            Yup.string().oneOf(getEnumStringValues(EducationLevel))
          )
            .strict(true)
            .nullable(),
    employmentStatus:
      memberProfileSettings.memberEmploymentStatusState ===
      MemberFieldState.Required
        ? ySelectOptionSchema(
            Yup.string().oneOf(getEnumStringValues(EmploymentStatus)).required()
          )
            .strict(true)
            .required("Employment Status is required")
        : ySelectOptionSchema(
            Yup.string().oneOf(getEnumStringValues(EmploymentStatus))
          )
            .strict(true)
            .nullable(),
    employerName:
      memberProfileSettings.memberEmployerNameState ===
      MemberFieldState.Required
        ? Yup.string().trim().required("Employer Name is required")
        : Yup.string().trim().nullable(true),
    maritalStatus:
      memberProfileSettings.memberMaritalStatusState ===
      MemberFieldState.Required
        ? ySelectOptionSchema(
            Yup.string().oneOf(getEnumStringValues(MaritalStatus)).required()
          )
            .strict(true)
            .required("Marital Status is required")
        : ySelectOptionSchema(
            Yup.string().oneOf(getEnumStringValues(MaritalStatus))
          )
            .strict(true)
            .nullable(),
  });
};
