import { useMutation, useQuery, useSubscription } from "@apollo/client";
import {
  QueryPaginatedNotificationsArgs,
  NotificationsResponse,
  NotificationResponse,
  MutationDeleteNotificationArgs,
  MutationMarkNotificationAsReadArgs,
  Notification,
  NotificationsMetadataResponse,
  NotificationSettingsResponse,
  MutationUpdateNotificationSettingsArgs,
  SubscriptionNotificationReceivedArgs,
  MutationMarkAllNotificationAsReadArgs,
  MutationSendPlatformNotificationArgs,
} from "../schemaTypes";
import {
  DELETE_NOTIFICATION,
  MARK_NOTIFICATION_AS_READ,
  MARK_ALL_NOTIFICATION_AS_READ,
  SEND_NOTIFICATION_MANUALLY,
  UPDATE_NOTIFICATION_SETTINGS,
  SEND_PLATFORM_NOTIFICATION,
} from "./mutations";
import {
  NOTIFICATIONS_METADATA,
  NOTIFICATION_SETTINGS,
  PAGINATED_NOTIFICATIONS,
} from "./queries";
import { NOTIFICATION_RECEIVED } from "./subscription";
import toast from "src/libs/toast";

export const useQueryPaginatedNotifications = (
  showOnlyUnRead: boolean,
  page: number,
  organizationId: string
) =>
  useQuery<
    { paginatedNotifications: NotificationsResponse },
    QueryPaginatedNotificationsArgs
  >(PAGINATED_NOTIFICATIONS, {
    variables: {
      showOnlyUnRead,
      page,
    },
  });

export const useQueryNotificationsMetadata = () =>
  useQuery<{ notificationsMetadata: NotificationsMetadataResponse }>(
    NOTIFICATIONS_METADATA
  );

export const useMutationDeleteNotification = (
  notificationId: string,
  organizationId: string
) =>
  useMutation<
    { deleteNotification: NotificationResponse },
    MutationDeleteNotificationArgs
  >(DELETE_NOTIFICATION, {
    variables: {
      notificationId,
    },
    refetchQueries: [
      {
        query: NOTIFICATIONS_METADATA,
        variables: { organizationId },
      },
    ],
  });

export const useMutationMarkNotificationAsRead = (
  notificationId: string,
  organizationId: string
) =>
  useMutation<
    { markNotificationAsRead: NotificationResponse },
    MutationMarkNotificationAsReadArgs
  >(MARK_NOTIFICATION_AS_READ, {
    variables: {
      notificationId,
    },
    refetchQueries: [
      {
        query: NOTIFICATIONS_METADATA,
        variables: { organizationId },
      },
    ],
  });

export const useMutationMarkAllNotificationsAsRead = (organizationId: string) =>
  useMutation<
    { markAllNotificationAsRead: NotificationResponse },
    MutationMarkAllNotificationAsReadArgs
  >(MARK_ALL_NOTIFICATION_AS_READ, {
    variables: {
      organizationId: organizationId,
    },
    refetchQueries: [
      {
        query: PAGINATED_NOTIFICATIONS,
        variables: { showOnlyUnRead: false, page: 0, organizationId },
      },
      {
        query: NOTIFICATIONS_METADATA,
        variables: { organizationId },
      },
    ],
  });

export const useMutationSendNotificationManually = () =>
  useMutation<{ sendNotificationManually: NotificationResponse }>(
    SEND_NOTIFICATION_MANUALLY
  );

export const useMutationSendPlatformNotification = () =>
  useMutation<
    { sendPlatformNotification: NotificationResponse },
    MutationSendPlatformNotificationArgs
  >(SEND_PLATFORM_NOTIFICATION);

export const useQueryNotificationSettings = () =>
  useQuery<{ notificationSettings: NotificationSettingsResponse }>(
    NOTIFICATION_SETTINGS
  );

export const useMutationUpdateNotificationSettings = () =>
  useMutation<
    { updateNotificationSettings: NotificationSettingsResponse },
    MutationUpdateNotificationSettingsArgs
  >(UPDATE_NOTIFICATION_SETTINGS, {
    refetchQueries: [NOTIFICATION_SETTINGS],
  });

export const useNotificationReceivedSubscription = (organizationId: string) =>
  useSubscription<
    { notificationReceived: Notification },
    SubscriptionNotificationReceivedArgs
  >(NOTIFICATION_RECEIVED, {
    variables: {
      organizationId: organizationId,
    },

    onData(options) {
      if (!options.data.data?.notificationReceived?._id) {
        return;
      }

      toast.success("You have new notification!", {
        duration: 3000,
        icon: "+1",
        style: { marginTop: "50px" },
      });

      options.client.refetchQueries({
        include: [NOTIFICATIONS_METADATA],
      });
    },
  });
