import {
  Badge,
  Divider,
  Group,
  Select,
  Stack,
  Tabs,
  Text,
} from "@mantine/core";
import Messages from "src/components/twilio/messages";
import { useAuthContext } from "src/hooks";
import useTwilio from "src/hooks/useTwilio";
import { formatPhoneNumber } from "src/utils/phone";
import { NumberPad } from "./NumberPad";
import { forwardRef, useEffect, useState } from "react";
import { WidgetTab } from ".";
import { Member, MemberPhoneNumber, PhoneNumberType } from "src/graphql";
import { getFullName } from "src/utils";
import { User } from "react-feather";

type MemberContextProps = {
  member: Member;
};

export const MemberContext = ({ member }: MemberContextProps) => {
  const { widget } = useTwilio();

  const { selectedOrganization } = useAuthContext();
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<
    WrappedPhoneNumber | undefined
  >(() => getDefaultNumber(member.contactInfo));

  useEffect(() => {
    setSelectedPhoneNumber(getDefaultNumber(member.contactInfo));
  }, [member.contactInfo]);


  const memberDisplayName = getFullName(member);
  const primaryPhoneNumberDigits =
    member.contactInfo.primaryPhoneNumber?.digits ?? "";
  const wrappedNumbers = member.contactInfo.phoneNumbers.map((p) =>
    wrapPhoneNumber(p, primaryPhoneNumberDigits)
  );

  return (
    <Stack spacing={0}>
      {/* Header */}
      <Group px="12px" h={40} bg="black.1" spacing={"xs"} position="left">
        <User size={14} />
        <Text size="md">{memberDisplayName}</Text>
      </Group>

      {!!wrappedNumbers.length && (
        <Stack spacing={12} p={12}>
          <Select
            itemComponent={PhoneNumberSelectItem}
            value={selectedPhoneNumber?.value}
            searchable={false}
            onChange={(nextId: string) =>
              setSelectedPhoneNumber(
                wrappedNumbers.find((p) => p._id === nextId)
              )
            }
            data={wrappedNumbers}
          />

          {/* badges for selected number */}
          {selectedPhoneNumber && (
            <Group spacing={5}>
              {selectedPhoneNumber.isPrimary && <Badge>Primary</Badge>}
              {selectedPhoneNumber.type === PhoneNumberType.Mobile && (
                <Badge>Mobile</Badge>
              )}
              {selectedPhoneNumber.doNotCall && (
                <Badge color="red">Do Not Call</Badge>
              )}
            </Group>
          )}
        </Stack>
      )}

      <Divider color="black.1" mb={2} />

      <Tabs
        value={widget.tab}
        onTabChange={(nextTab) => widget.setTab(nextTab as WidgetTab)}
      >
        <Stack>
          <Tabs.List>
            <Tabs.Tab
              value={WidgetTab.Phone}
              disabled={selectedPhoneNumber?.doNotCall}
            >
              Phone
            </Tabs.Tab>
            <Tabs.Tab
              value={WidgetTab.Sms}
              disabled={selectedPhoneNumber?.type === PhoneNumberType.Landline}
            >
              Texting
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={WidgetTab.Phone} mih={300}>
            {!selectedPhoneNumber && "No phone number selected"}
            {selectedPhoneNumber && (
              <NumberPad
                selectedNumberDigits={selectedPhoneNumber.digits}
                doNotCall={selectedPhoneNumber.doNotCall}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel value={WidgetTab.Sms}>
            {!selectedPhoneNumber && "No phone number selected"}
            {selectedPhoneNumber && (
              <Messages
                organization={selectedOrganization}
                participantPhone={selectedPhoneNumber.digits}
              />
            )}
          </Tabs.Panel>
        </Stack>
      </Tabs>
    </Stack>
  );
};

type WrappedPhoneNumber = MemberPhoneNumber & {
  value: string;
  label: string;
  isPrimary: boolean;
};

const wrapPhoneNumber = (
  number: MemberPhoneNumber,
  primaryNumberDigits?: string
) => ({
  ...number,
  value: number._id,
  label: formatPhoneNumber(number.digits),
  isPrimary: number.digits === primaryNumberDigits,
});

const getDefaultNumber = ({
  phoneNumbers,
  primaryPhoneNumber,
}: Member["contactInfo"]) => {
  let selectedNumber: MemberPhoneNumber | undefined;

  // primary if set
  selectedNumber = primaryPhoneNumber;

  // otherwise pick mobile & callable
  if (!selectedNumber)
    selectedNumber = phoneNumbers.find(
      (n) => n.type === PhoneNumberType.Mobile && !n.doNotCall
    );

  // otherwise pick callable
  if (!selectedNumber) selectedNumber = phoneNumbers.find((n) => !n.doNotCall);

  return selectedNumber
    ? wrapPhoneNumber(selectedNumber, primaryPhoneNumber?.digits)
    : undefined;
};

type PhoneNumberSelectItemProps = React.ComponentPropsWithoutRef<"div"> &
  WrappedPhoneNumber;

const PhoneNumberSelectItem = forwardRef<
  HTMLDivElement,
  PhoneNumberSelectItemProps
>(
  (
    { type, isPrimary, doNotCall, label, ...rest }: PhoneNumberSelectItemProps,
    ref
  ) => {
    return (
      <Stack spacing={5} ref={ref} {...rest}>
        <Text size="sm">{label}</Text>
        <Group spacing={5}>
          {isPrimary && <Badge>Primary</Badge>}
          {type === PhoneNumberType.Mobile && <Badge>Mobile</Badge>}
          {doNotCall && <Badge color="red">Do Not Call</Badge>}
        </Group>
      </Stack>
    );
  }
);
