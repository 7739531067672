import { Button, Select, Stack, Text } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useState } from "react";
import {
  LoaderComponent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
  StyledLabel,
} from "src/components";
import { ScheduleActivityInput, useQueryActivityTemplate } from "src/graphql";
import { useAuthContext } from "src/hooks";
import { SelectOption } from "src/types";

interface ScheduleActivityInputProps {
  templateId: string;
  assignableUserOptions: SelectOption<string>[];
  assignableUsersLoading: boolean;
  completeFunction: (scheduleInfo: ScheduleActivityInput) => void;
  onboardingToken?: string;
  relatedCarePathwayTemplateFamilyId?: string;
}

export const ScheduleActivityActionModalInput: React.FC<
  ScheduleActivityInputProps
> = ({
  completeFunction,
  templateId,
  assignableUserOptions,
  assignableUsersLoading,
  onboardingToken,
  relatedCarePathwayTemplateFamilyId,
}) => {
  const { currentUser, selectedOrganizationId } = useAuthContext();
  const [dateTime, setDateTime] = useState<Date | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(
    onboardingToken ? null : (currentUser?._id ?? null)
  );
  const { data: activityTemplateResponse, loading: activityTemplateLoading } =
    useQueryActivityTemplate(
      templateId,
      selectedOrganizationId,
      onboardingToken
    );

  return (
    <>
      <ModalHeader withSubHeader>Schedule Activity</ModalHeader>

      {activityTemplateLoading && <LoaderComponent />}

      {!activityTemplateLoading && (
        <>
          <ModalSubHeader>
            {onboardingToken && (
              <Stack spacing="sm">
                <Text>
                  Please select a time to schedule a call from our organization
                  regarding:
                </Text>
                <Text>
                  {
                    activityTemplateResponse?.activityTemplate?.data
                      ?.activityTemplate.title
                  }
                </Text>
              </Stack>
            )}

            {!onboardingToken &&
              `Please assign a user and select a time to schedule this member's "${activityTemplateResponse?.activityTemplate?.data?.activityTemplate.title}" activity.`}
          </ModalSubHeader>

          <ModalBody spacing="sm">
            {assignableUsersLoading && <LoaderComponent />}

            {!assignableUsersLoading && (
              <>
                {/* Don't show user assignment in the member self-administered "onboarding" context */}
                {!onboardingToken && (
                  <div>
                    <StyledLabel>Assigned User (optional)</StyledLabel>
                    <Select
                      data={assignableUserOptions}
                      value={selectedUserId}
                      disabled={assignableUserOptions.length <= 1}
                      onChange={setSelectedUserId}
                    />
                  </div>
                )}

                <div>
                  <StyledLabel>Time *</StyledLabel>
                  <DateTimePicker
                    value={dateTime}
                    onChange={setDateTime}
                    placeholder="Select a time"
                    popoverProps={{ withinPortal: true }}
                  />
                </div>
              </>
            )}
          </ModalBody>
        </>
      )}

      <ModalFooter>
        <Button
          disabled={
            !dateTime || assignableUsersLoading || activityTemplateLoading
          }
          onClick={() => {
            // null case is impossible given the above check
            completeFunction({
              activityTime: dateTime?.toISOString() ?? "",
              activityTemplateId: templateId,
              userId: selectedUserId ?? undefined,
              carePathwayTemplateFamilyId: relatedCarePathwayTemplateFamilyId,
              scheduleImmediately: false,
            });
            setDateTime(null);
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </>
  );
};
