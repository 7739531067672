import { gql } from "@apollo/client";
import { GoalTemplateFields } from "../GoalTemplate/types";
import { ActivityTemplateFieldsMin } from "../ActivityTemplate/types";
import { flowTemplateFields, flowTemplateFieldsMin } from "./types";
import { ExternalResourceFieldsMin } from "../ExternalResource/types";
import { carePathwayTemplateMinimalFieldsForFlowBuilder } from "../CarePath/types";
import { consentTemplateFields } from "../Consent/types";

export const GET_FLOW_TEMPLATES = gql`
  query GetFlowTemplates($organizationId: ID!) {
    flowTemplates(organizationId: $organizationId) {
      success
      message
      data {
        flowTemplate {
          ${flowTemplateFields}
        }
        accessType
      }
    }
  }
`;

export const GET_FLOW_TEMPLATE = gql`
  query GetFlowTemplate($input: FlowTemplateIdAndOrgInput!) {
    flowTemplate(input: $input ) {
      success
      message
      data {
        flowTemplate {
          ${flowTemplateFields}
        }
        accessType
      }
    }
  }
`;

export const GET_FLOW_TEMPLATE_FAMILY = gql`
  query GetFlowTemplateFamily($input: FlowTemplateFamilyIdAndOrgInput!) {
    flowTemplateFamily(input: $input) {
      success
      message
      data {
        currentVersion {
          ${flowTemplateFields}
        }
        priorVersions {
          ${flowTemplateFields}
        }
      }
    }
  }
`;

export const GET_FLOW_TEMPLATE_FAMILIES = gql`
  query GetFlowTemplateFamilies($organizationId: ID!) {
    flowTemplateFamilies(organizationId: $organizationId) {
      success
      message
      data {
        currentVersion {
            ${flowTemplateFields}
        }
        priorVersions {
          ${flowTemplateFields}
        }
      }
    }
  }
`;

export const GET_FLOW_BUILDER_DATA = gql`
  query GetFlowTemplateActionOptions($organizationId: ID!) {
    goalTemplates(organizationId: $organizationId) {
      data {
        accessType
        goalTemplate {
            ${GoalTemplateFields}
        }
      }
      success
      message
    }
    flowTemplateFamilies(organizationId: $organizationId) {
      data {
        accessType
        flowTemplateFamily {
          currentVersion {
            ${flowTemplateFieldsMin}
          }
          priorVersions {
            ${flowTemplateFieldsMin}
          }
        }
      }
      success
      message
    }
    activityTemplates(organizationId: $organizationId) {
      data {
        accessType
        activityTemplate { 
          ${ActivityTemplateFieldsMin}
        }
      }
      success
      message
    }
    externalResources(organizationId: $organizationId) {
      data {
        ${ExternalResourceFieldsMin}
      }
    }
    carePathwayTemplateFamiliesByOrganizationId(organizationId: $organizationId) {
      data {
            currentVersion{
                ${carePathwayTemplateMinimalFieldsForFlowBuilder}
            }
            priorVersions{
                ${carePathwayTemplateMinimalFieldsForFlowBuilder}
           }
      }
    }
    consentTemplatesByOrganizationId(organizationId: $organizationId) {
      data {
        ${consentTemplateFields}
      }
    }
  }
`;
