export const formatMoney = (value: string | number) => {
  const parsedValue = typeof value === "string" ? parseFloat(value) : value;
  if (Number.isNaN(parsedValue)) return "$ ";

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  });

  return formatter.format(parsedValue);
};
