import { Tooltip, TooltipProps, useMantineTheme } from "@mantine/core";
import { Info, IconProps } from "react-feather";

type InfoTooltipProps = Omit<TooltipProps, "children"> & {
  iconProps?: IconProps;
  iconStyle?: React.CSSProperties;
};

export const InfoTooltip = ({
  iconProps,
  iconStyle,
  ...tooltipProps
}: InfoTooltipProps) => {
  const theme = useMantineTheme();

  return (
    <Tooltip {...tooltipProps}>
      <Info
        size={16}
        color={theme.colors.blue[5]}
        {...iconProps}
        style={iconStyle}
      />
    </Tooltip>
  );
};
