import { ActionIcon, Center, Dialog, Group } from "@mantine/core";
import { ArrowLeft, Minus, Phone } from "react-feather";

import { useAuthContext } from "src/hooks";
import useTwilio from "src/hooks/useTwilio";
import { SelectContext } from "./SelectContext";
import { MemberContext } from "./MemberContext";
import { PhoneNumberContext } from "./PhoneNumberContext";

export enum WidgetTab {
  Sms = "sms",
  Phone = "phone",
}

export const TwilioPhoneWidget = () => {
  const { selectedOrganization } = useAuthContext();
  const { widget, isCallInProgress, isIncomingCallInProgress } = useTwilio();

  if (!selectedOrganization.callerId) return null;

  const hasContext = widget.context.type !== "contextSelect";
  const canUnsetContext = !isCallInProgress && !isIncomingCallInProgress;

  return (
    <>
      <ActionIcon
        color="green"
        variant="filled"
        p="xl"
        radius="xl"
        pos="absolute"
        right={50}
        bottom={50}
        onClick={widget.expand}
        style={{ zIndex: 199 }}
        data-testid="twilio-widget"
      >
        <Center>
          <Phone />
        </Center>
      </ActionIcon>

      <Dialog
        p={0}
        opened={widget.isOpen}
        size="lg"
        transition={"pop-bottom-right"}
        style={{ overflow: "hidden" }}
        position={{ bottom: 50, right: 50 }}
      >
        {/* Header */}
        <Group position="apart" spacing={0} p={0} bg="green" w="100%">
          {/* Context Target */}
          {hasContext && (
            <>
              {/* Back Btn*/}
              {canUnsetContext && (
                <>
                  <HeaderActionIcon onClick={widget.unsetContext}>
                    <ArrowLeft color="white" size={16} />
                  </HeaderActionIcon>
                </>
              )}
            </>
          )}

          {/* Collapse Btn*/}
          <HeaderActionIcon
            onClick={widget.collapse}
            style={{ marginLeft: "auto" }}
          >
            <Minus color="white" size={16} />
          </HeaderActionIcon>
        </Group>

        {/* Content */}
        {widget.context.type === "contextSelect" && <SelectContext />}
        {widget.context.type === "member" && (
          <MemberContext member={widget.context.member} />
        )}
        {widget.context.type === "phoneNumber" && (
          <PhoneNumberContext digits={widget.context.digits} />
        )}
      </Dialog>
    </>
  );
};

type HeaderActionIconProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick: () => void;
};

const HeaderActionIcon = ({
  onClick,
  children,
  style,
}: HeaderActionIconProps) => (
  <ActionIcon
    onClick={onClick}
    style={style}
    radius={0}
    h={40}
    w={40}
    sx={{
      ":hover": {
        backgroundColor: "var(--color-pear-green-highlight)",
      },
    }}
  >
    {children}
  </ActionIcon>
);
