import DOMPurify from "dompurify";

/**
 * Make sure the anchor opens in a new tab and add security attributes
 * @param anchor Anchor <a> DOM node
 */
function parseAnchor(anchor: HTMLAnchorElement) {
  if (!anchor.hasAttribute("target")) {
    anchor.setAttribute("target", "_blank");
  }
  anchor.setAttribute("rel", "noopener noreferrer");
  return anchor;
}

/**
 * Parse HTML by creating an element and going through it's internals
 *  - All anchors should be safe.See #parseAnchor
 * @param html Html string
 */
function parseHtml(html: string): string {
  const container = document.createElement("div");
  container.innerHTML = html;

  Array.from(container.querySelectorAll("a"), parseAnchor);

  return container.innerHTML;
}

/**
 * Sanitizes HTML to prevent XSS attacks
 * @param html HTML string to sanitize
 * @returns Sanitized HTML string safe for dangerouslySetInnerHTML
 */
export function sanitizeHtml(html: string | undefined): string {
  if (!html) return "";
  return DOMPurify.sanitize(html);
}

/**
 * Return a parsed HTML string
 * @param html HTML string
 */
export default function createMarkup(html: string) {
  return { __html: sanitizeHtml(parseHtml(html)) };
}

export const convertToPlain = (html?: string) => {
  if (!html) return "";
  // Create a new div element
  const tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
};
