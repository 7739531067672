import { Modal, ModalBody, ModalFooter, ModalHeader } from "../modal";
import { useAuthContext } from "src/hooks/useAuthContext";
import useTwilio from "src/hooks/useTwilio";

import { Button } from "@mantine/core";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

const SessionTimeout = () => {
  const { signOut } = useAuthContext();
  const { isCallInProgress, isIncomingCallInProgress, incomingCall } =
    useTwilio();
  // Set timeout values
  const timeout = 1000 * 60 * 9.5; // 9.5 minutes of inactivity
  const promptBeforeIdle = 1000 * 30; // 30 seconds left to stay signed in

  // Modal open state
  const [open, setOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    // skip 10 minute idle signout in development
    if (process.env.NODE_ENV === "development") {
      handleStillHere();
    } else {
      setOpen(true);
      setRemaining(promptBeforeIdle);
    }
  };

  const onIdle = () => {
    if (isCallInProgress || isIncomingCallInProgress || incomingCall) {
      handleStillHere();
      return;
    }
    setOpen(false);
    setRemaining(0);
    signOut();
  };

  const onActive = () => {
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    onActive,
    crossTab: true,
  });

  const handleStillHere = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <>
      <Modal opened={open} onClose={handleStillHere}>
        <ModalHeader>Inactive Session</ModalHeader>
        <ModalBody>Logging you out in {remaining} seconds</ModalBody>
        <ModalFooter>
          <Button onClick={handleStillHere}>I'm Still Here</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default SessionTimeout;
