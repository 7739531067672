import type { MemberFormValues } from "../MemberFormFormik";
import { FormikInput, FormikMultiSelect, FormikSelect } from "src/components";
import { FormikDateInput } from "src/components/input/FormikDateInput";
import {
  EducationLevel,
  EmploymentStatus,
  Ethnicity,
  Language,
  MaritalStatus,
  MemberFieldState,
  MemberProfileSettings,
  PregnancyStatus,
} from "src/graphql";
import { SelectOption } from "src/types";

import { Grid, TextInput, Title } from "@mantine/core";
import { Field, useFormikContext } from "formik";

interface MemberProfileInfoProps {
  memberProfileSettings: MemberProfileSettings;
  raceSelectOptions: SelectOption<string>[];
  sexSelectOptions: SelectOption<string>[];
  genderSelectOptions: SelectOption<string>[];
  pronounsSelectOptions: SelectOption<string>[];
  sexualOrientationSelectOptions: SelectOption<string>[];
  pregnancyStatusSelectOptions: SelectOption<PregnancyStatus>[];
  languageSelectOptions: SelectOption<Language>[];
  ethnicitySelectOptions: SelectOption<Ethnicity>[];
  employmentStatusSelectOptions: SelectOption<EmploymentStatus>[];
  maritalStatusSelectOptions: SelectOption<MaritalStatus>[];
  highestLevelOfEducationSelectOptions: SelectOption<EducationLevel>[];
  shouldShowTitle: boolean;
}

export const MemberProfileInformationSection = ({
  memberProfileSettings,
  raceSelectOptions,
  sexSelectOptions,
  genderSelectOptions,
  pronounsSelectOptions,
  sexualOrientationSelectOptions,
  pregnancyStatusSelectOptions,
  languageSelectOptions,
  ethnicitySelectOptions,
  shouldShowTitle,
  employmentStatusSelectOptions,
  maritalStatusSelectOptions,
  highestLevelOfEducationSelectOptions,
}: MemberProfileInfoProps) => {
  const { errors, touched } = useFormikContext<MemberFormValues>();
  return (
    <>
      {shouldShowTitle && (
        <Title order={2} style={{ marginBottom: 20 }}>
          Profile
        </Title>
      )}
      <Grid gutter="md" px={10}>
        {memberProfileSettings.memberPreferredNameState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            {shouldShowTitle ? (
              <Field
                as={TextInput}
                label="Preferred Name"
                type="text"
                name="nickName"
                variant="filled"
                radius="md"
                error={touched.nickName && errors.nickName}
                placeholder="Enter Preferred Name"
                required={
                  memberProfileSettings.memberPreferredNameState ===
                  MemberFieldState.Required
                }
              />
            ) : (
              <FormikInput
                type="text"
                name="nickName"
                label="Preferred Name"
                placeholder="Enter Preferred Name"
                required={
                  memberProfileSettings.memberPreferredNameState ===
                  MemberFieldState.Required
                }
              />
            )}
          </Grid.Col>
        )}
        {memberProfileSettings.memberDOBState !== MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikDateInput
              name="dob"
              label="Date of Birth"
              placeholder="YYYY-MM-DD"
              required={
                memberProfileSettings.memberDOBState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberRaceState !== MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikMultiSelect
              name="race"
              label="Race(s)"
              options={raceSelectOptions}
              placeholder="Select one or more races"
              required={
                memberProfileSettings.memberRaceState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberEthnicityState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikSelect
              label="Ethnicity"
              name="ethnicity"
              options={ethnicitySelectOptions}
              placeholder="Enter Ethnicity"
              required={
                memberProfileSettings.memberEthnicityState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberSexState !== MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikSelect
              name="sex"
              label="Sex"
              options={sexSelectOptions}
              required={
                memberProfileSettings.memberSexState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberGenderState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikMultiSelect
              name="gender"
              label="Gender Identity(s)"
              options={genderSelectOptions}
              required={
                memberProfileSettings.memberGenderState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberPronounsState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikMultiSelect
              name="pronouns"
              label="Pronouns"
              options={pronounsSelectOptions}
              required={
                memberProfileSettings.memberPronounsState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberSexualOrientationState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikMultiSelect
              name="sexualOrientation"
              options={sexualOrientationSelectOptions}
              label="Sexual Orientation(s)"
              required={
                memberProfileSettings.memberSexualOrientationState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberPregnancyState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikSelect
              name="pregnancyStatusEnum"
              label="Pregnancy Status"
              options={pregnancyStatusSelectOptions}
              required={
                memberProfileSettings.memberPregnancyState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberLanguagesState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikMultiSelect
              name="spokenLanguages"
              options={languageSelectOptions}
              label="Spoken Language(s)"
              required={
                memberProfileSettings.memberLanguagesState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberEmploymentStatusState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikSelect
              name="employmentStatus"
              options={employmentStatusSelectOptions}
              label="Employment Status"
              required={
                memberProfileSettings.memberEmploymentStatusState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberEmployerNameState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikInput
              type="text"
              name="employerName"
              label="Employer Name"
              placeholder="Enter Employer Name"
              required={
                memberProfileSettings.memberEmployerNameState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberMaritalStatusState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikSelect
              name="maritalStatus"
              options={maritalStatusSelectOptions}
              label="Marital Status"
              required={
                memberProfileSettings.memberMaritalStatusState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberHighestLevelOfEducationState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikSelect
              name="highestLevelOfEducation"
              options={highestLevelOfEducationSelectOptions}
              label="Highest Level of Education"
              required={
                memberProfileSettings.memberHighestLevelOfEducationState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberPeopleInHouseholdState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikInput
              type="number"
              name="peopleInHousehold"
              label="Number of People In Household"
              placeholder="Enter Number of People In Household"
              required={
                memberProfileSettings.memberPeopleInHouseholdState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberChildrenInHouseholdState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikInput
              type="number"
              name="childrenInHousehold"
              label="Number of Children In Household"
              placeholder="Enter Number of Children In Household"
              required={
                memberProfileSettings.memberChildrenInHouseholdState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberMaidenNameOrAliasState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikInput
              type="text"
              name="maidenNameOrAlias"
              label="Maiden Name or Alias"
              placeholder="Enter Maiden Name or Alias"
              required={
                memberProfileSettings.memberMaidenNameOrAliasState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
        {memberProfileSettings.memberMedicalRecordNumberState !==
          MemberFieldState.Hidden && (
          <Grid.Col md={3} sm={6}>
            <FormikInput
              type="text"
              name="medicalRecordNumber"
              label="Medical Record Number"
              placeholder="Enter Medical Record Number"
              required={
                memberProfileSettings.memberMedicalRecordNumberState ===
                MemberFieldState.Required
              }
            />
          </Grid.Col>
        )}
      </Grid>
    </>
  );
};
