import { LockingReferenceFields } from "../Common/types";

export const UserGroupFields = `
  _id
  title
  accessType
  users {
    _id
    name
  }
  lockingReferences {
    ${LockingReferenceFields}
  }
`;
