import { Button, Text } from "@mantine/core";
import toast from "src/libs/toast";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "src/components";
import { useMutationClaimIncomingPhoneCall } from "src/graphql";
import { useAuthContext } from "src/hooks";
import useTwilio from "src/hooks/useTwilio";
import { IncomingCall } from "src/types";
import { formatPhoneNumber } from "src/utils/phone";

function IncomingCallModal() {
  const {
    isCallIncoming,
    incomingCall,
    acceptIncomingCall,
    declineIncomingCall,
  } = useTwilio();

  return (
    <Modal
      opened={isCallIncoming}
      onClose={declineIncomingCall}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      {incomingCall && (
        <Call
          call={incomingCall}
          accept={acceptIncomingCall}
          decline={declineIncomingCall}
        />
      )}
    </Modal>
  );
}

type CallProps = {
  call: IncomingCall;
  accept: () => void;
  decline: () => void;
};

export const Call = ({ call, accept, decline }: CallProps) => {
  const { selectedOrganizationId } = useAuthContext();
  const [mutationClaimIncomingPhoneCall, { loading: claimInProgress }] =
    useMutationClaimIncomingPhoneCall();

  const handleClaimCall = async () => {
    try {
      const { data: claimResponse } = await mutationClaimIncomingPhoneCall({
        variables: {
          phoneCallId: call.phoneCallId,
          organizationId: selectedOrganizationId,
        },
      });

      if (!claimResponse?.claimIncomingPhoneCall.success)
        throw new Error(claimResponse?.claimIncomingPhoneCall.message);

      if (claimResponse.claimIncomingPhoneCall.data?.didClaim) {
        accept();
      } else {
        decline();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast.error(err.message ?? "Couldn't claim phone call");
    }
  };

  const isInternalTransfer = call.phone === call.organization.callerId;

  const { organization } = call;
  const formattedNumber = formatPhoneNumber(call.phone);
  const members = call.members
    ?.map((member) => member.memberDisplayName)
    .join(", ");

  const { selectedOrganization } = useAuthContext();

  const matchesCurrentOrganization =
    selectedOrganization && selectedOrganization?._id === organization?._id;

  return (
    <>
      <ModalHeader>Incoming call...</ModalHeader>

      <ModalBody spacing="xs">
        {!matchesCurrentOrganization && (
          <Text fw={700}>{organization?.title}</Text>
        )}
        <Text fw={700}>{members}</Text>
        <Text>{formattedNumber}</Text>
        {!matchesCurrentOrganization && (
          <Text fw={700}>
            Answering this call will loose any ongoing work with{" "}
            {selectedOrganization?.title} when switching to{" "}
            {organization?.title}.
          </Text>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          loading={claimInProgress}
          color="red"
          variant="outline"
          onClick={decline}
        >
          Decline
        </Button>

        <Button
          loading={claimInProgress}
          onClick={isInternalTransfer ? accept : handleClaimCall}
        >
          Answer
        </Button>
      </ModalFooter>
    </>
  );
};

export default IncomingCallModal;
