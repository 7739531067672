import {
  PreviewContainer,
  PreviewHeader,
  PreviewList,
  PreviewListItem,
  PreviewListMore,
} from "./shared";
import { GoalTemplate } from "src/graphql";
import { sanitizeHtml } from "src/utils/htmlParser";

type GoalTemplatePreviewProps = {
  template: GoalTemplate | null;
};

export const GoalTemplatePreview = ({ template }: GoalTemplatePreviewProps) => {
  const itemCount = template?.actionItemDescriptions?.length ?? 0;

  return (
    <PreviewContainer style={{ display: "flex", flexDirection: "column" }}>
      <PreviewHeader>Preview</PreviewHeader>
      {!template && "<missing template info>"}

      {template && (
        <div style={{ padding: 3 }}>
          {/* Name */}
          <div style={{ marginTop: "0.25em" }}>{template.name}</div>

          {/* Description */}
          <div
            style={{ marginTop: "0.5em" }}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(template.description || ""),
            }}
          />

          {/* Action Items */}
          {!itemCount && (
            <div style={{ marginTop: "0.5em" }}>
              {"<no action items configured>"}
            </div>
          )}

          {!!itemCount && (
            <>
              <div style={{ marginTop: "0.5em" }}>Action Items:</div>
              <PreviewList>
                {template.actionItemDescriptions
                  .slice(0, 3)
                  .map((description) => (
                    <PreviewListItem key={description}>
                      {description}
                    </PreviewListItem>
                  ))}
              </PreviewList>
              <PreviewListMore count={itemCount} />
            </>
          )}
        </div>
      )}
    </PreviewContainer>
  );
};
