import { Formik, FormikProps } from "formik";
import log from "loglevel";
import { useRef } from "react";
import toast from "src/libs/toast";
import * as Yup from "yup";

import { FormikSelect } from "src/components";
import { ActionInfo } from "src/components/flow-graph/util";
import {
  ActionType,
  CarePathwayStatus,
  CarePathwayTemplate,
  UpdateCarePathwayStatusData,
} from "src/graphql";
import { SelectOption } from "src/types";
import { ySelectOptionSchema } from "src/utils";
import { SubFormCommonProps } from "../AddActionModal";
import { Stack } from "@mantine/core";
import { CarePathwayTemplatePreview } from "../CarePathwayTemplatePreview";
import { Disclaimer } from "../shared";

type UpdateCarePathwayStatusActionFormProps = SubFormCommonProps & {
  carePathwayTemplateFamiliesOptions: SelectOption<string>[];
  carePathwayStatusOptions: SelectOption<CarePathwayStatus>[];
  carePathwayTemplatesByFamilyId: Record<string, CarePathwayTemplate>;
};

export const UpdateCarePathwayStatusActionForm = ({
  carePathwayTemplateFamiliesOptions,
  carePathwayStatusOptions,
  carePathwayTemplatesByFamilyId,
  innerRef,
  node,
  onCreateAction,
  onDirtyStateChange,
  onValidStateChange,
}: UpdateCarePathwayStatusActionFormProps) => {
  const dirtyStateRef = useRef(false);
  const validStateRef = useRef(false);

  const initialValues: WrappedFormValues = {
    carePathwayTemplateFamilyId: null,
    status: null,
  };

  return (
    <Formik
      innerRef={innerRef as React.RefObject<FormikProps<WrappedFormValues>>}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (!node) return; // nothing renders without a node selected
        try {
          setSubmitting(true);
          const actionInfo = parseFormValues(formValues);
          log.log("formSubmit", actionInfo, formValues);
          onCreateAction(node, actionInfo);
          // eslint-disable-next-line
        } catch (err: any) {
          log.error(err.message);
          setSubmitting(false);
          toast.error("Failed to add action; please try again");
        }
      }}
    >
      {({ values, dirty, isValid }) => {
        if (onDirtyStateChange && dirty !== dirtyStateRef.current) {
          dirtyStateRef.current = dirty;
          requestAnimationFrame(() => onDirtyStateChange(dirty));
        }
        if (onValidStateChange && isValid !== validStateRef.current) {
          validStateRef.current = isValid;
          requestAnimationFrame(() => onValidStateChange(isValid));
        }

        return (
          <Stack mt="0.75em" spacing="1em">
            <FormikSelect
              name="carePathwayTemplateFamilyId"
              label="Care Pathway Template"
              options={carePathwayTemplateFamiliesOptions}
            />

            {values.carePathwayTemplateFamilyId && (
              <div>
                <CarePathwayTemplatePreview
                  carePathwayTemplate={
                    carePathwayTemplatesByFamilyId[
                      values.carePathwayTemplateFamilyId.value
                    ]
                  }
                />
              </div>
            )}

            <FormikSelect
              name="status"
              label="Status"
              options={carePathwayStatusOptions}
            />

            {values.status && (
              <Disclaimer>
                Note: A <strong>Care Pathway Status Update</strong> will succeed
                if this Flow is part of an existing Care Pathway. Otherwise, the
                Flow will continue transparently.
              </Disclaimer>
            )}
          </Stack>
        );
      }}
    </Formik>
  );
};

type WrappedFormValues = Omit<
  UpdateCarePathwayStatusData,
  "carePathwayTemplateFamilyId" | "status"
> & {
  carePathwayTemplateFamilyId: SelectOption<string> | null;
  status: SelectOption<CarePathwayStatus> | null;
};

const parseFormValues = (formValues: WrappedFormValues): ActionInfo => {
  if (!formValues.carePathwayTemplateFamilyId) {
    throw new Error(
      "Missing care pathway template ID - check schema validator"
    );
  }
  if (!formValues.status) {
    throw new Error("Missing care pathway status - check schema validator");
  }

  return {
    actionType: ActionType.UpdateCarePathwayStatus,
    actionData: {
      carePathwayTemplateFamilyId: formValues.carePathwayTemplateFamilyId.value,
      status: formValues.status.value,
    },
  };
};

const ValidationSchema = Yup.object({
  carePathwayTemplateFamilyId: ySelectOptionSchema(
    Yup.string().required()
  ).required(),
  status: ySelectOptionSchema(
    Yup.mixed<CarePathwayStatus>()
      .oneOf(Object.values(CarePathwayStatus))
      .required()
  ).required(),
});
