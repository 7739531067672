import { Anchor, Group, Stack, Tabs, Text } from "@mantine/core";
import { Phone } from "react-feather";
import Messages from "src/components/twilio/messages";
import { useAuthContext } from "src/hooks";
import useTwilio from "src/hooks/useTwilio";
import { formatPhoneNumber } from "src/utils/phone";
import { NumberPad } from "./NumberPad";
import { WidgetTab } from ".";
import { useQueryMemberInfoForPhoneNumber } from "src/graphql";
import { LoaderComponent } from "src/components/loader";
import { StyledLabel } from "src/components/input";
import { Link } from "react-router-dom";
import { DontTranslate } from "src/libs/localization/components/DontTranslate";

type PhoneNumberContextProps = {
  digits: string;
};

export const PhoneNumberContext = ({ digits }: PhoneNumberContextProps) => {
  const { selectedOrganization } = useAuthContext();
  const { widget } = useTwilio();

  const { data: memberInfoResponse, loading: memberInfoLoading } =
    useQueryMemberInfoForPhoneNumber(selectedOrganization._id, digits);

  const memberInfo = memberInfoResponse?.memberInfoForPhoneNumber.data ?? [];

  return (
    <Stack spacing={0}>
      {/* Header */}
      <Group px="12px" h={40} bg="black.1" spacing={"xs"} position="left">
        <Phone size={14} />
        <Text size="md">{formatPhoneNumber(digits)}</Text>
      </Group>

      <Stack align={"flex-start"} spacing={0} px={"12px"} py={"8px"}>
        {/* loading members */}
        {memberInfoLoading && <LoaderComponent h={"20px"} w={"20px"} />}

        {/* loaded, members found */}
        {!memberInfoLoading && (
          <>
            {memberInfo.length > 0 && (
              <Stack spacing={0}>
                <StyledLabel mb={0}>Known Members:</StyledLabel>

                <div>
                  {memberInfo.map((info, i) => (
                    <>
                      <Anchor
                        size="xs"
                        component={Link}
                        to={`/members/${info.memberId}`}
                      >
                        <DontTranslate>{info.memberDisplayName}</DontTranslate>
                      </Anchor>
                      {i !== memberInfo.length - 1 && ", "}
                    </>
                  ))}
                </div>
              </Stack>
            )}

            {/* loaded, no members found */}
            {memberInfo.length === 0 && (
              <StyledLabel color="grey">
                No known members at this number
              </StyledLabel>
            )}
          </>
        )}
      </Stack>

      <Tabs
        value={widget.tab}
        onTabChange={(nextTab) => widget.setTab(nextTab as WidgetTab)}
      >
        <Stack>
          <Tabs.List>
            <Tabs.Tab value={WidgetTab.Phone}>Phone</Tabs.Tab>
            <Tabs.Tab value={WidgetTab.Sms}>Texting</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={WidgetTab.Phone} mih={300}>
            <NumberPad selectedNumberDigits={digits} />
          </Tabs.Panel>

          <Tabs.Panel value={WidgetTab.Sms}>
            <Messages
              organization={selectedOrganization}
              participantPhone={digits}
            />
          </Tabs.Panel>
        </Stack>
      </Tabs>
    </Stack>
  );
};
