import { isValidPhoneNumber } from "libphonenumber-js";
import { useState } from "react";
import { WidgetTab } from "src/components/twilio/widget";
import { Member } from "src/graphql";
import toast from "src/libs/toast";
import { IncomingCall } from "src/types";

type WidgetContext =
  | {
      type: "member";
      member: Member;
    }
  | {
      type: "phoneNumber";
      digits: string;
    }
  | { type: "contextSelect" };

export const useTwilioWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState<WidgetTab | null>(WidgetTab.Phone);
  const [context, setContext] = useState<WidgetContext>({
    type: "contextSelect",
  });

  const handleAcceptCall = (call: IncomingCall) => {
    setContext({ type: "phoneNumber", digits: call.phone });
    setTab(WidgetTab.Phone);
    setIsOpen(true);
  };

  const handleSelectMember = (member: Member, shouldOpen = true) => {
    setTab(WidgetTab.Phone);
    setContext({ type: "member", member });
    if (shouldOpen) setIsOpen(true);
  };

  const handleSelectPhoneNumber = async (
    digits: string,
    tab: WidgetTab = WidgetTab.Phone
  ) => {
    if (!isValidPhoneNumber(digits, { defaultCountry: "US" })) {
      toast.error("Member Phone Number is invalid");
      return;
    }

    setContext({ type: "phoneNumber", digits });
    setTab(tab);
    setIsOpen(true);
  };

  const handleUnsetContext = () => {
    setTab(WidgetTab.Phone);
    setContext({ type: "contextSelect" });
  };

  const handleCollapse = () => {
    setIsOpen(false);
  };

  return {
    tab,
    isOpen,
    context,
    unsetContext: handleUnsetContext,
    selectMember: handleSelectMember,
    selectPhoneNumber: handleSelectPhoneNumber,
    acceptCall: handleAcceptCall,
    expand: () => setIsOpen(true),
    collapse: handleCollapse,
    setTab,
  };
};
