import toast from "src/libs/toast";
import log from "loglevel";
import { useAuth0 } from "@auth0/auth0-react";

const apiUrl = process.env.REACT_APP_API_URL;

export const useRequest = () => {
  const { getAccessTokenSilently } = useAuth0();

  return async (
    endpoint = "",
    params = {},
    parseAsJSON = true,
    showToast = true,
    sendContentType = false
  ) => {
    let token = "";

    try {
      token = await getAccessTokenSilently(); // Fetch token from Auth0
    } catch (e) {
      log.error("Failed to get access token", e);
      if (showToast) toast.error("Failed to authenticate");
      return;
    }

    return fetch(`${apiUrl}${endpoint}`, {
      ...params,
      // if sendContentType is true, set the content-type to application/json
      headers: sendContentType
        ? {
            "Content-Type": "application/json",
            authorization: token ? `Bearer ${token}` : "",
          }
        : {
            authorization: token ? `Bearer ${token}` : "",
          },
    })
      .then(async (res) => {
        if (res.status >= 200 && res.status < 400) {
          return parseAsJSON ? res.json() : res;

          /**
           * Code block to enable once content-type is added in the request from the backend
           */
          // const contentType = res.headers.get("content-type");
          // if (contentType && contentType.includes("application/json")) {
          //   return res.json().then((error = "") => {
          //     toast.error(error);
          //   });
          // }

          // // Return text if we are not dealing with a JSON response object
          // return res.text();
        } else {
          let message = res.statusText;

          const contentType = res.headers.get("content-type") as string | null;
          if (contentType && contentType.includes("application/json")) {
            const data = await res.json();
            message = data?.result?.message ?? message;
          }

          if (showToast) toast.error(message);
        }
      })
      .catch((e) => {
        log.error({ e });
        if (showToast) toast.error("Something went wrong!");
        else throw e;
      });
  };
};
