import { BillingProviderFields } from "../Organization/types";

export const UserFields = `
  _id
  name
  email
  organizationIds
  assignedMemberIds
  createdAt
  updatedAt
  isActive
  spokenLanguages
  location
  certifications {
    _id
    title
    description
    isActive
    fileId
  }
  trainings {
    _id
    name
    userId
    organizationId
    documentationId
    revokedAt
    issueDate
    expirationDate
    continuingEducationUnits
  }
  renderingProvider { ${BillingProviderFields} }
  recommendingProvider {
    _id
    nationalProviderIdentifier
    firstName
    lastName
    title
    faxNumber
    companyName
    isEmailVerified
  }
  userOrgRoles {
    roleId
    roleName
    permissions
    organizationId
    isEditable
  }
  isSuperAdmin
`;

export const UserStatFields = `
  _id
  email
  name
  billableUnits
  billableAmount
  numActiveMember
  numActivitiesScheduled
  numActivitiesCompleted
  numGoalsCreated
  numGoalsCompleted
  isActive
  createdAt
  updatedAt
  status
  onlineDurationInMinutes
`;
