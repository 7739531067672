import { useField } from "formik";
import { ChangeEvent } from "react";
import { Input, InputProps } from "@mantine/core";
import {
  extractWrapperProps,
  FormikInputBaseProps,
  FormikInputWrapper,
} from "./FormikInputWrapper";

type FormikInputProps = FormikInputBaseProps<string> & {
  type: React.InputHTMLAttributes<HTMLInputElement>["type"];
  onChangeOverride?: (event: ChangeEvent<HTMLInputElement>) => void;
  // Note, adding props directly to this union breaks the types
  props?: InputProps;
};

export const FormikInput = (props: FormikInputProps) => {
  const {
    className,
    style,
    disabled,
    onChangeOverride,
    autoFocus,
    props: mantineProps,
    ...rest
  } = props;

  const [field] = useField(rest);

  return (
    <FormikInputWrapper {...extractWrapperProps(props)}>
      <Input
        {...mantineProps}
        {...field}
        style={style}
        autoFocus={autoFocus}
        placeholder={rest.placeholder}
        type={rest.type}
        disabled={disabled}
        className={className}
        onChange={
          onChangeOverride
            ? (e: ChangeEvent<HTMLInputElement>) => onChangeOverride(e)
            : (e: ChangeEvent<HTMLInputElement>) => field.onChange(e)
        }
      />
    </FormikInputWrapper>
  );
};
