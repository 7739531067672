import { gql } from "@apollo/client";

export const aclForObjectTypeFields = `
  name
  _id
  objectId
  accessType
  subOrgs {
    orgId
    name
    accessType
  }
`;
export const GET_ACL_FOR_OBJECT_TYPE = gql`
  query GetACLForObjectType(
    $organizationId: ID!
    $objectType: ObjectType!
    $pagination: PaginationInput!
  ) {
    response: getACLForObjectType(
      organizationId: $organizationId
      objectType: $objectType
      pagination: $pagination
    ) {
      success
      message
      data {
        total
        page
        data {
          ${aclForObjectTypeFields}
        }
      }
    }
  }
`;

export const GET_ACL_FOR_MEMBER_OBJECT_TYPE = gql`
  query GetACLForMemberObjectType(
    $organizationId: ID!
    $pagination: PaginationInput!
  ) {
    response: getACLForMemberObjectType(
      organizationId: $organizationId
      pagination: $pagination
    ) {
      success
      message
      data {
        total
        page
        data {
          ${aclForObjectTypeFields}
        }
      }
    }
  }
`;

export const GET_SUB_ORGS_BY_PARENT_ID = gql`
  query GetSubOrgsByParentId($parentId: ID!, $recursive: Boolean) {
    getSubOrgsByParentId(organizationId: $parentId, recursive: $recursive) {
      success
      message
      data {
        _id
        title
        createdAt
        updatedAt
        parentId
        seats
        seatsUsed
      }
    }
  }
`;
