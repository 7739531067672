import { Modal, ModalBody, ModalFooter, ModalHeader } from "../modal";

import { ActionIcon, Button, TextInput, Tooltip } from "@mantine/core";
import { Fragment, ReactNode, useCallback, useEffect, useState } from "react";

type PermanentDeleteButtonProps = {
  title: string;
  disabled?: boolean;
  loading?: boolean;
  checkValue: string;
  checkLabel: string;
  onSubmit: () => Promise<boolean>;
  size?: "xs";
  icon: ReactNode;
  children: ReactNode;
};

export const PermanentDeleteButton = (props: PermanentDeleteButtonProps) => {
  const [confirmationModalIsOpen, setConfirmationModelIsOpen] = useState(false);

  const onSubmit = useCallback(() => {
    props
      .onSubmit()
      .then((success) => {
        if (success) setConfirmationModelIsOpen(false);
      })
      .catch(console.error);
  }, [props]);

  return (
    <Fragment>
      <Tooltip label={props.title}>
        <ActionIcon
          size={props.size ?? "sm"}
          color="red"
          onClick={() => setConfirmationModelIsOpen(true)}
          disabled={props.disabled}
        >
          {props.icon}
        </ActionIcon>
      </Tooltip>

      <ConfirmationModal
        title={props.title}
        checkValue={props.checkValue}
        checkLabel={props.checkLabel}
        isLoading={props.loading}
        isOpen={confirmationModalIsOpen}
        onClose={() => setConfirmationModelIsOpen(false)}
        onDelete={onSubmit}
      >
        {props.children}
      </ConfirmationModal>
    </Fragment>
  );
};

type ConfirmationModalProps = {
  title: string;
  isOpen: boolean;
  isLoading?: boolean;
  checkValue: string;
  checkLabel: string;
  onClose: () => void;
  onDelete: () => void;
  children: ReactNode;
};

const ConfirmationModal = ({
  title,
  isOpen,
  isLoading,
  checkValue,
  checkLabel,
  onClose,
  onDelete,
  children,
}: ConfirmationModalProps) => {
  const [confirmation, setConfirmation] = useState("");

  useEffect(() => {
    setConfirmation("");
  }, [isOpen]);

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      closeOnClickOutside={!isLoading}
      closeOnEscape={!isLoading}
    >
      <ModalHeader bg="red">{title}</ModalHeader>

      <ModalBody spacing="md">
        {children}

        <TextInput
          label={checkLabel}
          placeholder={checkValue}
          value={confirmation}
          onChange={(event) => setConfirmation(event.currentTarget.value)}
        />
      </ModalBody>

      <ModalFooter>
        <Button variant="outline" disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>

        <Button
          color="red"
          disabled={confirmation !== checkValue}
          loading={isLoading}
          onClick={onDelete}
        >
          {title}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
