import { DataTableComponent } from "src/components";
import { Recommendation } from "src/graphql";
import { getFormattedDate } from "src/utils";
import { sanitizeHtml } from "src/utils/htmlParser";

import { Text } from "@mantine/core";
import { TableColumn } from "react-data-table-component";

type RecommendationsTableProps = {
  recommendations: Recommendation[];
  onSelectRecommendation: (recommendationId: string) => void;
};

export const RecommendationsTable = ({
  recommendations,
  onSelectRecommendation,
}: RecommendationsTableProps) => {
  const columns: TableColumn<Recommendation>[] = [
    {
      name: "Name",
      sortable: true,
      selector: (rec) => rec.name,
    },
    {
      name: "Description",
      sortable: true,
      selector: (rec) => rec.recommendationText,
      cell: (rec) => (
        <Text
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(rec.recommendationText),
          }}
          style={{ pointerEvents: "none" }}
        ></Text>
      ),
    },
    {
      name: "Created Date",
      sortable: true,
      selector: (rec) => new Date(rec.createdAt).getTime(),
      cell: (rec) => getFormattedDate(rec.createdAt),
    },
  ];

  return (
    <DataTableComponent
      onRowClicked={(row: Recommendation) => onSelectRecommendation(row._id)}
      keyField="_id"
      noDataText="Create a Recommendation to get started!"
      columns={columns}
      data={recommendations}
      pointerOnHover
      highlightOnHover
      noCardWrapper
    />
  );
};
