import { getEnumStringValues } from "./enum";
import {
  AnswerType,
  DataPoint,
  EducationLevel,
  EmploymentStatus,
  EnrollmentStatus,
  Ethnicity,
  Gender,
  Language,
  MaritalStatus,
  PregnancyStatus,
  Pronouns,
  Sex,
  SexualOrientation,
} from "src/graphql";

export const dataPointContainsText = (text: string, dataPoint: DataPoint) => {
  return (
    dataPoint.answer.includes(text) ||
    dataPoint.dataId.includes(text) ||
    dataPoint.questionText?.includes(text)
  );
};

export const compareDataPoints = (a: DataPoint, b: DataPoint) => {
  return (
    a.dataId.localeCompare(b.dataId) ||
    (a.questionText ?? "").localeCompare(b.questionText ?? "") ||
    (a.createdAt ?? "").localeCompare(b.createdAt ?? "")
  );
};

export const memberFieldToDataIdMap = {
  firstName: "D1",
  lastName: "D2",
  nickName: "D3",
  gender: "D4",
  dob: "D5",
  sex: "D8",
  "contactInfo.address": "D6",
  "contactInfo.primaryPhoneNumber.digits": "D7",
  "contactInfo.email": "D13",
  "contactInfo.address2": "D53",
  "contactInfo.zip": "D60",
  race: "D25",
  spokenLanguages: "D26",
  "primaryInsuranceCompany.insurance": "D31",
  ethnicity: "D44",
  medicaidStatus: "D45",
  medicareStatus: "D46",
  pronouns: "D47",
  sexualOrientation: "D50",
  diagnosisCodes: "D48",
  phonetic: "D67",
  pregnancyStatus: "D68",
  primaryCIN: "D69",
  "secondaryInsuranceCompany.insurance": "D70",
  secondaryCIN: "D71",
  timezone: "D72",
  diagnoses: "D73",
  prescriptions: "D74",
  "contactInfo.city": "D75",
  "contactInfo.country": "D76",
  "contactInfo.state": "D77",
  socialSecurityNumber: "D78",
  groups: "D79",
  maidenNameOrAlias: "D80",
  medicalRecordNumber: "D81",
  highestEducationLevel: "D82",
  employmentStatus: "D83",
  employerName: "D84",
  maritalStatus: "D85",
  peopleInHousehold: "D86",
  childrenInHousehold: "D87",
  createdAt: "D94",
  closureReason: "D95",
  emergencyContactName: "D96",
  emergencyContactPhone: "D97",
  emergencyContactEmail: "D98",
  emergencyContactRelation: "D99",
  emergencyContactCanBeCalled: "D100",
  emergencyContactCanBeTexted: "D101",
  primaryCareProviderName: "D102",
  primaryCareProviderPhone: "D103",
  primaryCareProviderEmail: "D104",
  primaryCareProviderRelation: "D105",
  primaryCareProviderCanBeCalled: "D106",
  primaryCareProviderCanBeTexted: "D107",
  caregiverName: "D108",
  caregiverPhone: "D109",
  caregiverEmail: "D110",
  caregiverRelation: "D111",
  caregiverCanBeCalled: "D112",
  caregiverCanBeTexted: "D113",
} as const;

export type ReservedDataId =
  (typeof memberFieldToDataIdMap)[keyof typeof memberFieldToDataIdMap];

export const reservedDataIdLabels: Record<ReservedDataId, string> = {
  D1: "First Name",
  D2: "Last Name",
  D3: "Nick Name",
  D4: "Gender",
  D5: "Date of Birth",
  D6: "Address 1",
  D53: "Address 2",
  D7: "Phone",
  D8: "Sex",
  D13: "Email",
  D60: "Zip Code",
  D25: "Race",
  D26: "Spoken Languages",
  D31: "Primary Insurance Company",
  D44: "Ethnicity",
  D45: "Medicaid Status",
  D46: "Medicare Status",
  D47: "Pronouns",
  D50: "Sexual Orientation",
  D48: "Diagnosis Codes",
  D67: "Name Pronunciation",
  D68: "Pregnancy Status",
  D69: "Primary Client Identification Number (CIN)",
  D70: "Secondary Insurance Company",
  D71: "Secondary Client Identification Number (CIN)",
  D72: "Timezone",
  D73: "Diagnoses",
  D74: "Prescriptions",
  D75: "City",
  D76: "Country",
  D77: "State",
  D78: "Social Security Number",
  D79: "Groups",
  D80: "Maiden Name or Alias",
  D81: "Medical Record Number",
  D82: "Highest Level of Education",
  D83: "Employment Status",
  D84: "Employer Name",
  D85: "Marital Status",
  D86: "People in Household",
  D87: "Children in Household",
  D94: "Created At",
  D95: "Closure Reason",
  D96: "Emergency Contact Name",
  D97: "Emergency Contact Phone",
  D98: "Emergency Contact Email",
  D99: "Emergency Contact Relationship",
  D100: "Emergency Contact Can Be Called",
  D101: "Emergency Contact Can Be Texted",
  D102: "Primary Care Provider Name",
  D103: "Primary Care Provider Phone",
  D104: "Primary Care Provider Email",
  D105: "Primary Care Provider Relationship",
  D106: "Primary Care Provider Can Be Called",
  D107: "Primary Care Provider Can Be Texted",
  D108: "Caregiver Name",
  D109: "Caregiver Phone",
  D110: "Caregiver Email",
  D111: "Caregiver Relationship",
  D112: "Caregiver Can Be Called",
  D113: "Caregiver Can Be Texted",
} as const;

export const reservedDataIdSet = new Set(Object.keys(reservedDataIdLabels));

export const reservedDataIdAnswerTypes: Record<ReservedDataId, AnswerType> = {
  D1: AnswerType.Text,
  D2: AnswerType.Text,
  D3: AnswerType.Text,
  D4: AnswerType.MultiChoice,
  D5: AnswerType.Date,
  D6: AnswerType.Text,
  D53: AnswerType.Text,
  D7: AnswerType.Text,
  D8: AnswerType.Multi,
  D13: AnswerType.Text,
  D60: AnswerType.Text,
  D25: AnswerType.MultiChoice,
  D26: AnswerType.MultiChoice,
  D31: AnswerType.Text,
  D44: AnswerType.Multi,
  D45: AnswerType.Multi,
  D46: AnswerType.Multi,
  D47: AnswerType.MultiChoice,
  D50: AnswerType.MultiChoice,
  D48: AnswerType.MultiChoice,
  D67: AnswerType.Text,
  D68: AnswerType.Multi,
  D69: AnswerType.Text,
  D70: AnswerType.Text,
  D71: AnswerType.Text,
  D72: AnswerType.Multi,
  D73: AnswerType.MultiChoice,
  D74: AnswerType.MultiChoice,
  D75: AnswerType.Text,
  D76: AnswerType.Multi,
  D77: AnswerType.Multi,
  D78: AnswerType.Text,
  D79: AnswerType.MultiChoice,
  D80: AnswerType.Text,
  D81: AnswerType.Text,
  D82: AnswerType.Multi,
  D83: AnswerType.Multi,
  D84: AnswerType.Text,
  D85: AnswerType.Multi,
  D86: AnswerType.Number,
  D87: AnswerType.Number,
  D94: AnswerType.Date,
  D95: AnswerType.Text,
  D96: AnswerType.Text,
  D97: AnswerType.Text,
  D98: AnswerType.Text,
  D99: AnswerType.Text,
  D100: AnswerType.Boolean,
  D101: AnswerType.Boolean,
  D102: AnswerType.Text,
  D103: AnswerType.Text,
  D104: AnswerType.Text,
  D105: AnswerType.Text,
  D106: AnswerType.Boolean,
  D107: AnswerType.Boolean,
  D108: AnswerType.Text,
  D109: AnswerType.Text,
  D110: AnswerType.Text,
  D111: AnswerType.Text,
  D112: AnswerType.Boolean,
  D113: AnswerType.Boolean,
} as const;

export const getReservedDataIDFromPlaceholder = (placeholder: string) => {
  return (Object.keys(reservedDataIdLabels) as Array<ReservedDataId>).find(
    (did) => reservedDataIdLabels[did] === placeholder
  );
};

export const dataIdFormatMessage =
  "Data ID's must be of the form <Capital Letter(s)><Number(s)>, e.g. A1, BGD12, etc...";

export const prohibitedCalculationDataIdsByField = {
  emergencyContactName: "D96",
  emergencyContactPhone: "D97",
  emergencyContactEmail: "D98",
  emergencyContactRelation: "D99",
  emergencyContactCanBeCalled: "D100",
  emergencyContactCanBeTexted: "D101",

  primaryCareProviderName: "D102",
  primaryCareProviderPhone: "D103",
  primaryCareProviderEmail: "D104",
  primaryCareProviderRelation: "D105",
  primaryCareProviderCanBeCalled: "D106",
  primaryCareProviderCanBeTexted: "D107",

  caregiverName: "D108",
  caregiverPhone: "D109",
  caregiverEmail: "D110",
  caregiverRelation: "D111",
  caregiverCanBeCalled: "D112",
  caregiverCanBeTexted: "D113",
};

export const prohibitedCalculationDataIdsSet = new Set(
  Object.values(prohibitedCalculationDataIdsByField)
);

export const dataIdLabelRestrictionsForCalculations: Record<string, string[]> =
  {
    D8: getEnumStringValues(Sex),
    D4: getEnumStringValues(Gender),
    D50: getEnumStringValues(SexualOrientation),
    D47: getEnumStringValues(Pronouns),
    D26: getEnumStringValues(Language),
    D44: getEnumStringValues(Ethnicity),
    D68: getEnumStringValues(PregnancyStatus),
    D82: getEnumStringValues(EducationLevel),
    D83: getEnumStringValues(EmploymentStatus),
    D85: getEnumStringValues(MaritalStatus),
    D45: getEnumStringValues(EnrollmentStatus),
    D46: getEnumStringValues(EnrollmentStatus),
  } as const;
