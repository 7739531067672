import {
  RouteDescription,
  SideBarSection,
  SidebarSections,
} from "../../Router";
import { MASTHEAD_HEIGHT } from "./constants";
import iconPushPinSlash from "src/assets/svgs/icon-push-pin-slash.svg";
import iconPushPin from "src/assets/svgs/icon-push-pin.svg";
import { Permission } from "src/graphql";
import { useAuthContext, useLocalStorageState } from "src/hooks";

import { Stack } from "@mantine/core";
import { Icon as TablerIcon } from "@tabler/icons-react";
import React, { useMemo, useState } from "react";
import { Icon, Menu } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

export const COLLAPSED_WIDTH = 52;
export const EXPANDED_WIDTH = 250;

const OuterWrapper = styled.div<{ $pinned: boolean }>`
  position: fixed;
  top: ${MASTHEAD_HEIGHT}px;
  height: calc(100vh - ${MASTHEAD_HEIGHT}px);
  width: ${(props) =>
    props.$pinned ? `${EXPANDED_WIDTH}px` : `${COLLAPSED_WIDTH}px`};
  display: flex;
  flex-shrink: 0;
  position: relative;
`;

const InnerWrapper = styled.div<{ $pinned: boolean; $hovered: boolean }>`
  overflow: hidden;
  position: absolute;
  z-index: 10;
  transition: all 0.2s;
  width: ${(props) =>
    props.$pinned || props.$hovered
      ? `${EXPANDED_WIDTH}px`
      : `${COLLAPSED_WIDTH}px`};

  ${(props) =>
    props.$hovered &&
    !props.$pinned &&
    `
    -webkit-box-shadow: 2px 0px 4px 0px rgba(0,0,0,0.12);
            box-shadow: 2px 0px 4px 0px rgba(0,0,0,0.12);`}
`;

const ContentContainer = styled.div`
  width: ${EXPANDED_WIDTH}px;
  background: var(--color-pear-green);
  color: #fff;
  height: calc(100vh - ${MASTHEAD_HEIGHT}px);
  flex-shrink: 0;
  z-index: 12;
  overflow: auto;
`;

const sectionSeparatorMixin = (props: { $expanded: boolean }) => `
  position: relative;

  // non-full-width border-bottom
  &:not(:last-child):after {
    display: block;
    content: "";
    height: 1px;
    width: ${
      props.$expanded ? `${EXPANDED_WIDTH - 12}px` : `${COLLAPSED_WIDTH - 12}px`
    };
    background-color: var(--color-pear-green-muted);
    position: absolute;
    bottom: 0px;
    left: 6px;
    transition: all 0.2s;
  }
`;

const HeaderContainer = styled.div<{ $expanded: boolean; $showBar: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${MASTHEAD_HEIGHT}px;

  ${(props) => props.$showBar && sectionSeparatorMixin(props)}
`;

const SectionContainer = styled.div<{ $expanded: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0px;

  ${(props) => sectionSeparatorMixin(props)}
`;

const StyledNavItem = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  height: 40px;
  color: ${(props) => (props.$selected ? "var(--color-pear-green)" : "white")};
  background-color: ${(props) =>
    props.$selected ? "var(--color-white-muted)" : "transparent"};

  &:hover {
    background-color: ${(props) =>
      props.$selected
        ? "rgba(255, 255, 255, 0.9)"
        : "var(--color-pear-green-highlight)"};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: ${COLLAPSED_WIDTH}px;
`;

const PinToggleWrapper = styled(IconWrapper)<{ $pinned: boolean }>`
  cursor: pointer;
  & svg {
    fill: white;
  }

  &:hover {
    background-color: var(--color-pear-green-highlight);
  }
  &:active {
    background-color: transparent;
  }
`;

export const Sidebar = () => {
  const ctx = useAuthContext();
  const [pinned, setPinned] = useLocalStorageState(false, "sidebar-pinned");
  const [hovered, setHovered] = useState(false);

  const filteredSidebarSections = useMemo(() => {
    const filteredSections: SideBarSection[] = [];

    for (const section of SidebarSections) {
      const filteredChildren: RouteDescription[] = [];

      for (const route of section.children) {
        if (route.omit && route.omit(ctx)) {
          continue;
        }

        if (ctx.hasAnyPermission(...route.allowedPermissions)) {
          filteredChildren.push(route);
        }
      }

      if (filteredChildren.length) {
        filteredSections.push({ ...section, children: filteredChildren });
      }
    }

    return filteredSections;
  }, [ctx]);

  return (
    <OuterWrapper $pinned={pinned}>
      <InnerWrapper
        $pinned={pinned}
        $hovered={hovered}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <ContentContainer>
          <HeaderContainer
            $expanded={hovered || pinned}
            $showBar={!ctx.hasExactPermissions(Permission.RecommendingProvider)}
          >
            <IconWrapper>
              <Menu size={18} />
            </IconWrapper>
            <PinToggleWrapper
              $pinned={pinned}
              onClick={() => setPinned(!pinned)}
            >
              <img
                width={18}
                src={pinned ? iconPushPinSlash : iconPushPin}
                alt="toggle menu pin"
              />
            </PinToggleWrapper>
          </HeaderContainer>

          <Stack spacing={0}>
            {filteredSidebarSections.map((section, index) => (
              <SectionContainer $expanded={hovered || pinned} key={index}>
                {section.children.map((route) => {
                  return <PearNavItem key={route.path} {...route} />;
                })}
              </SectionContainer>
            ))}
          </Stack>
        </ContentContainer>
      </InnerWrapper>
    </OuterWrapper>
  );
};

type PearNavItemProps = {
  path: string;
  label: string;
  Icon: Icon | TablerIcon;
};

const PearNavItem = ({ path, label, Icon }: PearNavItemProps) => {
  const history = useHistory();
  const location = useLocation();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    history.push(path);
  };

  const isActive = () => {
    switch (label) {
      case "Templates":
        return location.pathname.startsWith("/templates/");
      case "Claims":
        return location.pathname.startsWith("/billing/");
      case "Organization":
        return location.pathname === "/organization";
      default:
        return location.pathname.startsWith(path);
    }
  };

  return (
    <a href={path} onClick={handleClick}>
      <StyledNavItem $selected={isActive()}>
        <IconWrapper>
          <Icon size={18} />
        </IconWrapper>
        {label}
      </StyledNavItem>
    </a>
  );
};
