import { getFormattedFrequency } from "./time";
import { FrequencyInterval } from "src/graphql";

import * as Yup from "yup";

/**
 * Maps a frequency interval to its display label
 */
export const mapFrequencyIntervalToLabel = (
  frequencyInterval: FrequencyInterval
): string => {
  switch (frequencyInterval) {
    case FrequencyInterval.AsNeeded:
      return "As Needed";
    case FrequencyInterval.Hour:
      return "Hour(s)";
    case FrequencyInterval.Day:
      return "Day(s)";
    case FrequencyInterval.Week:
      return "Week(s)";
    case FrequencyInterval.Month:
      return "Month(s)";
    case FrequencyInterval.Year:
      return "Year(s)";
  }
};

/**
 * Reusable Yup validation schema for medication forms
 */
export const MedicationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  dosage: Yup.number().nullable().min(0, "Dosage cannot be negative"),
  dosageUnit: Yup.string()
    .nullable()
    .when("dosage", {
      is: (dosage: number | null) => dosage !== null && dosage > 0,
      then: (schema) =>
        schema.required("Dosage unit is required when dosage is specified"),
    }),
  frequency: Yup.number().nullable(),
  frequencyInterval: Yup.string()
    .nullable()
    .when("frequency", {
      is: (frequency: number | undefined) => frequency && frequency > 0,
      then: (schema) =>
        schema
          .oneOf(Object.values(FrequencyInterval))
          .required(
            "Frequency interval is required when frequency is specified"
          ),
    }),
  fillDate: Yup.date().nullable(),
  notes: Yup.string().nullable(),
  customFields: Yup.array()
    .of(
      Yup.object({
        name: Yup.string().required("Field name is required"),
        value: Yup.string().required("Field value is required"),
      })
    )
    .nullable(),
});
/**
 * Returns frequency interval options for select components
 */
export const getFrequencyIntervalOptions = () =>
  Object.values(FrequencyInterval).map((interval) => ({
    value: interval,
    label: mapFrequencyIntervalToLabel(interval),
  }));

/**
 * Handles saving medication (create or update)
 */
export const formatMedicationForSave = (values: any) => {
  const modifiedDosage = Number(values.dosage) || undefined;
  return {
    ...values,
    dosage: modifiedDosage,
  };
};

/**
 * Formats the medication dosage for display
 */
export const formatMedicationDosage = (
  dosage?: number | null,
  dosageUnit?: string | null
) => {
  if (!dosage || !dosageUnit) return "";
  return `${dosage} ${dosageUnit}`;
};

/**
 * Formats the medication frequency for display
 * Delegates to the getFormattedFrequency function which handles special cases
 */
export const formatMedicationFrequency = (
  frequency?: number | null,
  frequencyInterval?: FrequencyInterval | null
) => {
  return getFormattedFrequency(
    frequency ?? undefined,
    frequencyInterval ?? undefined
  );
};
