import { PermanentDeleteButton } from "./PermanentDeleteButton";
import { Member, MemberStatus, useMutationDeleteMember } from "src/graphql";
import { useAuthContext } from "src/hooks";
import toast from "src/libs/toast";
import { getFullName } from "src/utils";

import { Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";

type MemberDeleteButtonProps = {
  member: Member;
  size?: "xs";
  onSubmit?: () => void;
};

export const MemberDeleteButton = ({
  member,
  size,
  onSubmit,
}: MemberDeleteButtonProps) => {
  const { selectedOrganizationId } = useAuthContext();

  const [mutationDeleteMember, { loading }] = useMutationDeleteMember(
    member._id,
    selectedOrganizationId
  );

  const fullName = getFullName(member);
  const handleDeleteMember = async () => {
    try {
      const response = await mutationDeleteMember();

      if (!response.data?.deleteMember.success)
        throw new Error(response.data?.deleteMember.message);

      toast.success("Scheduled to delete member!");
      onSubmit?.();

      return true;
    } catch (error) {
      const defaultErrorMessage = "Could not delete member, please try again";

      toast.error(
        error instanceof Error
          ? (error.message ?? defaultErrorMessage)
          : defaultErrorMessage
      );

      return false;
    }
  };

  return (
    <PermanentDeleteButton
      title="Delete Member Permanently"
      checkLabel="Type the member's name to confirm"
      checkValue={fullName}
      disabled={member.status !== MemberStatus.Active}
      icon={<IconTrash />}
      size={size}
      loading={loading}
      onSubmit={handleDeleteMember}
    >
      <Text>
        Are you sure you want to delete the member{" "}
        <Text display="inline" weight="bold">
          {fullName}
        </Text>{" "}
        permanently? This action cannot be undone.
      </Text>

      <Text>
        <Text display="inline" weight="bold">
          Deleting a member with associated health data will violate HIPAA
          regulations.
        </Text>{" "}
        Please ensure that no health data is linked to this member before
        proceeding.
      </Text>
    </PermanentDeleteButton>
  );
};
